import { axiosConfig } from "./axios.config";

const updateClientInfo = async (chat_id , {whatsapp_name, country_phone, email, documento}) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/update_client`,
    {
        "chat_id": chat_id,
        "name": whatsapp_name,
        "country": country_phone,
        "email": email,
        "DNI": documento,
        "photo": ""
    }
	);
	return res.data;
}

const getClientInfo = async (chat_id) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/get_client`,
    {
        "wa_id": chat_id
    }
	);
	return res.data;
}

// Comentario para actualizar frontend
export { updateClientInfo, getClientInfo }