import { axiosConfig } from "./axios.config";

const getAreas = async () => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/templates/permitted_areas`
  );
  return res.data;
};

const getPlantillas = async () => {
    const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/templates/with_no_parameters`
    );
    return res.data;
}

const sendPlantillas = async (body) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/templates/send_template`, 
  body
  );
  return res.data;
}

export { getAreas, getPlantillas, sendPlantillas }