interface Prop {
    prop: string;
}

const ConversationEmpty: React.FC<Prop> = ({prop}) => {

    let description = '';

    prop === 'admin' 
    ? description = 'Oh! Parece que en esta sección no hay conversaciones. Por favor, intente con otros filtros' 
    : description = 'Parece que no tienes ningún chat asignado. Asignate alguno para poder visualizarlo en esta sección'

    return (
        <div className="flex flex-col content-center text-center m-auto">
            <p className="text-2xl text-gray-800">Sin conversaciones</p>
            <p className="text-gray-700">{description}</p>
        </div>
    )
}

export default ConversationEmpty;