import { Badge, Checkbox } from "flowbite-react";
import { ConversationProps } from "../interfaces";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import ProfilePicture from "./ProfilePicture";
import Tag from "../interfaces/tag.interface";
import { useState } from "react";

export const Conversation: React.FC<ConversationProps> = ({
  name,
  lastMessageTimestamp,
  lastMessage,
  photo,
  phoneNumber,
  conversation_id,
  checked,
  setChecked,
  checkConversation,
  isChatSeen,
  agentFullName,
  agentAssigned,
  tags,
  isSelectChatOpen
}) => {

  const [importedTags, setImportedTags] = useState([])
  const handleCheck = (event: any) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  useEffect(()=> {
    setImportedTags(tags.slice(0, 3))
  }, [])

  const containsChineseCharacters = (texto: string) => {
    const regex =
      /[\p{Script=Hani}\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}]+/gu;
    return texto.replace(regex, "");
  };

  const formatTime = (date:Date) => {
    const dateDay = moment(date).calendar()
    if(dateDay.includes('hoy')) {
      return moment(date).format('LT'); 
    } else {
      return moment(date).format("ll").slice(0, -8) 
    }
  } 
  return (
    <div
      className={`flex flex-col w-full ${
        isChatSeen === false
          ? "bg-gradient-to-r from-white from-10%  to-emerald-300 to-90%"
          : "bg-white"
      }`}
    >
      <div className={`flex items-center px-2 w-full`}>
        <Checkbox
          className={` ${
            isSelectChatOpen ? "flex" : "hidden"
          } cursor-pointer flex self-center mr-1.5`}
          // className={`flex invisible cursor-pointer group-hover:visible checked:visible flex self-center mr-1.5`}
          id="conversation"
          onChange={handleCheck}
          onClick={event => event.stopPropagation()}
          value={conversation_id}
          checked={checked.includes(conversation_id)}
        />
        <div className="flex w-9/12 items-center">
          <div className="flex mr-3">
            <ProfilePicture
              fullName={containsChineseCharacters(name)}
              size={12}
              fontSize={"xl"}
              photo={photo}
            />
          </div>
          <div className="w-10/12 font-medium flex flex-col justify-center">
            {name.length ? (
              <p className="truncate">{`${containsChineseCharacters(name)}`}</p>
            ) : (
              <p className="truncate">{phoneNumber}</p>
            )}
            <p className="text-sm text-gray-500 truncate text-left">
              {lastMessage &&
                lastMessage
                  .split(/(\*.*?\*|_.*?_|https?:\/\/\S+)/)
                  .map((part: any, index: any) => {
                    if (part.startsWith("*") && part.endsWith("*")) {
                      return (
                        <span key={index} className="font-bold">
                          {part.slice(1, -1)}
                        </span>
                      );
                    } else if (part.startsWith("_") && part.endsWith("_")) {
                      return (
                        <span key={index} className="italic">
                          {part.slice(1, -1)}
                        </span>
                      );
                    } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                      return (
                        <a
                          key={index}
                          className="text-principalColor"
                          href={part}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {part}
                        </a>
                      );
                    } else {
                      return <span key={index}>{part}</span>;
                    }
                  })}
            </p>
            <div className="flex w-full">
              <div className={"flex w-full"}>
                {tags
                  // .filter((tag: Tag) => tag.is_active === 1)
                  .slice(0, window.screen.width <= 1366 ? 3 : 4)
                  .map((t: Tag) => (
                    <Badge
                      className="hover:bg-gray-500 m-2 rounded-full w-6 h-6 text-center text-xs justify-center break-words"
                      style={{
                        color: t.color == "#FFFFFF" ? "#121211" : t.color,
                        backgroundColor: "white",
                        border:
                          t.color == "#FFFFFF"
                            ? `2px solid #121211`
                            : `2px solid ${t.color}`,
                      }}
                      title={t.name}
                      key={t.id}
                    >
                      {t.name.slice(0, 2)}
                    </Badge>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-3/12 items-end mr-2">
          <div className="text-gray-500 text-xs py-1 text-end">
            {formatTime(lastMessageTimestamp)}
          </div>
          <div className="flex items-center">
            <div
              className={`flex text-center justify-end text-base mt-1 ml-2 ${
                isChatSeen === 0 ? "text-white" : "text-principalColor"
              }`}
              title={agentFullName}
            >
              {agentAssigned === null ? (agentFullName.split(" ").map((name) => name.charAt(0))) : (
                <>
                {agentAssigned && agentAssigned.slice(0, 5)}
                </>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 mt-2"></div>
    </div>
  );
};
