import { Modal } from "flowbite-react";
import React, { useContext } from "react";
import userContext from "../utils/userContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface DataInterface {
  person_id: number | null;
  property: string;
  value: number;
  conversation_id: number;
}

interface Props {
  content?: string;
  value: string;
  btnValue: string;
  data?: {
    person_id: number | null;
    property: string;
    value: number;
    conversation_id: number;
  };
  setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
  isToggled?: boolean;
  activity?: string;
  fn?: any;
}

const OpenModal: React.FC<Props> = ({
  content,
  value,
  btnValue,
  data,
  setIsToggled,
  isToggled,
  fn,
  activity,
}) => {
  const {
    isModalOpen,
    setModalOpen,
    isAlert,
    setIsAlert,
    clientUser,
    setClientUser,
  } = useContext(userContext) as any;

  const queryClient = useQueryClient();

  // const handleChangeStatus = useMutation(
  //   (data: DataInterface | undefined) => changeStatus(data),
  //   {
  //     onSuccess: (res) => {
  //       activity &&
  //         postActivity(
  //           activity,
  //           clientUser.conversation_id,
  //           clientUser.person_id,
  //           36
  //         );
  //       queryClient.invalidateQueries({
  //         queryKey: ["allUsersData"],
  //       });
  //       if (data?.property) {
  //         if (data?.property === "bot_enabled") {
  //           setClientUser({ ...clientUser, [data?.property]: res.property });
  //         } else {
  //           setClientUser({});
  //         }
  //       }

  //       setIsAlert({
  //         ...isAlert,
  //         isOpen: true,
  //         title: res.message,
  //         type: "success",
  //       });
  //     },
  //     onError: (err: any) => {
  //       setIsAlert({
  //         ...isAlert,
  //         isOpen: true,
  //         title: err.response.data.message,
  //         type: "failure",
  //       });
  //     },
  //   }
  // );

  // const sendRequest = () => {
  //   if (data) {
  //     handleChangeStatus.mutate(data);
  //   }
  //   setModalOpen({ ...isModalOpen, [value]: false });
  // };

  const closeModal = () => {
    if (isToggled !== undefined && setIsToggled) {
      setIsToggled(!isToggled);
    }
    setModalOpen({ ...isModalOpen, [value]: false });
  };

  return (
    <Modal
      show={!!isModalOpen[value]}
      size="md"
      popup={true}
      onClose={() => closeModal()}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {content}
          </h3>
          <div className="flex justify-center gap-4">
            <button
              className="hover:bg-[#049380] rounded-lg text-sm w-32 py-3 bg-[#0fb6a1] text-white"
              onClick={() => fn() }
              // onClick={() => action()}
            >
              Si, {btnValue}
            </button>
            <button
              className="hover:bg-[#b42134] rounded-lg text-sm w-32 py-3 bg-[#db2840] text-white"
              onClick={() => closeModal()}
            >
              No, cancelar
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OpenModal;
