import React, { useState } from "react";

export interface Props {
  audioUrl: string;
}

const AudioPlayer: React.FC<Props> = ({ audioUrl }) => {
  return (
    <div className="flex">
      <audio controls>
        <source src={audioUrl} type="audio/ogg" />
      </audio>
    </div>
  );
};

export default AudioPlayer;
