import { Timeline } from "flowbite-react";
import moment from "moment-timezone";
import { HighlightEmpty } from "./HighlightEmpty";

type Highlight = {
  ID: string
  created_at: string
  creator_id: string
  description: string
  title: string
  updated_at: Date
}

interface Props {
  highlightsData: Highlight[]
}

export const Highlight: React.FC<Props> = ({highlightsData}) => {

  return (
    <div className="mx-2 mt-2 flex flex-col -z-10 h-full">
      {highlightsData?.length ? (
        <Timeline>
          {highlightsData?.map(
            (highlight: any, index: number) => (
              <Timeline.Item key={index}>
                <Timeline.Point />
                <Timeline.Content>
                  <Timeline.Time>
                    {moment(highlight.created_at).format("ll, LT") + " hs"}{" "}
                    <br />
                    {/* {highlight?.agent?.name &&
                      `Creado por: ${highlight.agent.name}`} */}
                  </Timeline.Time>
                  <Timeline.Title>{highlight.title}</Timeline.Title>
                  <Timeline.Body>{highlight.description}</Timeline.Body>
                </Timeline.Content>
              </Timeline.Item>
            )
          )}
        </Timeline>
      ) : (
        <HighlightEmpty />
      )}
    </div>
  );
};
