import { Button, Modal } from "flowbite-react";
import React from "react";
import FormConfig from "../FormConfig/FormConfig"
type Props = {
	show:string | boolean,
	onClose:(value: React.SetStateAction<string | boolean>) => void,
    tableSelected: string
    action: string
	selectedRow?: { 
        content: string 
        id: string
        name: string
        split_content: string[]
    }
	tableGetData: () => Promise<void>
}
const ModalConfig: React.FC<Props> = ({
    show, 
    tableSelected,
    action,
	selectedRow = {},
    onClose,
	tableGetData
}) => {

	return(
			<div className="modal-container">
					<Modal
						size="xl"
						popup={true}
						show={true}
						onClose={() => onClose(false)}
					>
						<Modal.Header

							onClick={async (event) => {
								event.stopPropagation();
							}}
						>
                            <h4 className="title-config-form">{action === 'add' ? 'Agregar' : action === 'delete' ? 'Eliminar' : 'Modificar'} {tableSelected}</h4>
                            </Modal.Header>
						<Modal.Body
							onClick={async (event) => {
								event.stopPropagation();
							}}
						>
							<div
								className="text-center fast-answers-container"
								onClick={async (event) => {
									event.stopPropagation();
								}}
							>
								<FormConfig 
									action={action} 
									tableSelected={tableSelected} 
									selectedRow={selectedRow} 
									tableGetData={tableGetData}
									closeModal={() => onClose(false)}
								/>
							</div>
						</Modal.Body>
					</Modal>
				</div>
	)
}

export default ModalConfig