import { Button, Modal } from "flowbite-react";
import React from "react";

type Props = {
	show:string | boolean,
	onClose:(value: React.SetStateAction<string | boolean>) => void,
	action:(action: string | boolean) => Promise<void>
}
const ConfirmationModal: React.FC<Props> = ({show, action, onClose}) => {
	const text = `${show === 'block' ? 'bloquear' : show === 'archived' ? 'archivar' : show === 'desassign' ? 'dejar' : 'asignar'}`
	return(
			<div className="modal-container">
					<Modal
						size="xl"
						popup={true}
						show={show !== false ? true : false}
						onClose={() => onClose(false)}
					>
						<Modal.Header
							onClick={async (event) => {
								event.stopPropagation();
							}}
						/>
						<Modal.Body
							onClick={async (event) => {
								event.stopPropagation();
							}}
						>
							<div
								className="text-center fast-answers-container"
								onClick={async (event) => {
									event.stopPropagation();
								}}
							>
								<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
									{`¿Está seguro que desea ${text} el chat?`}
								</h3>
								<div className="flex justify-center gap-4">
									<Button
										className="bg-principalColor"
										onClick={() => action(show)}
									>
										{`Si, ${text}`}
									</Button>
									<Button color="failure" onClick={() => onClose(false)}>
										No, cancelar
									</Button>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</div>
	)
}

export default ConfirmationModal