import {
  FcCallTransfer,
  FcFilingCabinet,
  FcOnlineSupport,
} from "react-icons/fc";
import { GoHubot } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { FilterDropdownProps } from "../interfaces";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  refetch,
  setFilter,
  setFilterDropdownSelected,
  setDropdownIsOpen,
  setIsOpen,
  setShowUnread,
}) => {
  const filterConversations = (filter: string) => {
    setShowUnread(false);
    console.log('cambio filtro: ', filter)
    setFilter(filter);
    // refetch();
  };

  return (
    <div className="justify-center align-middle absolute bg-gray-100 w-60 rounded-2xl hover:rounded-2xl p-2 mt-2 z-10">
      <button
        className="absolute top-0 right-0 p-2 focus:outline-none rounded-2xl"
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      >
        <MdClose size={20} />
      </button>
      <div className="hover:shadow-lg">
        <DropdownItem
          onClick={() => {
            filterConversations("BOT");
            setFilterDropdownSelected("Supervisar Bot");
            setDropdownIsOpen(false);
          }}
          className="justify-center"
        >
          <div className="flex items-center justify-center gap-2 pt-4 hover:bg-slate-100">
            <p>Supervisar bot</p>
            <GoHubot size={24} />
          </div>
        </DropdownItem>
      </div>
      <div className="hover:shadow-xl">
        <DropdownItem
          onClick={() => {
            filterConversations("WAITING AGENT");
            setFilterDropdownSelected("Esperando agente");
            setDropdownIsOpen(false);
          }}
          className="justify-center"
        >
          <div
            className="flex items-center justify-center gap-2 hover:bg-slate-100"
            title="Esperando agente"
          >
            <p>Esperando agente</p>
            <FcCallTransfer size={24} className="bg-transparent" />
          </div>
        </DropdownItem>
      </div>
      <div className="hover:shadow-xl">
        <DropdownItem
          onClick={() => {
            filterConversations("WITH AGENT");
            setFilterDropdownSelected("Con agente");
            setDropdownIsOpen(false);
          }}
          className="justify-center"
        >
          <div className="flex items-center justify-center gap-2 hover:bg-slate-100">
            <p>Con agente</p>
            <FcOnlineSupport size={24} />
          </div>
        </DropdownItem>
      </div>
      <div className="hover:shadow-xl">
        <DropdownItem
          onClick={() => {
            filterConversations("ARCHIVED");
            setFilterDropdownSelected("Atendidos");
            setDropdownIsOpen(false);
          }}
          className="justify-center"
        >
          <div className="flex items-center justify-center gap-2 pb-2 hover:bg-slate-100">
            <p>Atendidos</p>
            <FcFilingCabinet size={24} />
          </div>
        </DropdownItem>
      </div>
    </div>
  );
};

export default FilterDropdown;
