import React, { useContext, useEffect, useRef, useState } from "react";
import './MyInbox.css'
import { useQuery } from "@tanstack/react-query";
import { IoMdSend } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import { BsEmojiHeartEyesFill, BsCheck, BsFillClockFill,BsCheck2All, BsPersonFillDown } from "react-icons/bs";
import { HiPencil } from "react-icons/hi";
import { TbArchive, TbLockAccessOff } from "react-icons/tb";
import { SiRocketdotchat } from "react-icons/si";
import { RiChatOffLine } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import { MyInboxProps } from "../../interfaces/myInboxProps.interface";
import { modifyChats, assignChatsAdmin } from "../../services/ChatsActions.service";
import { io } from "socket.io-client";
import {getConversation, sendMessage} from "../../services/Messages.service"
import emojiArr from "../emoji";
import userContext from "../../utils/userContext";
import Skeleton from "../Skeleton";
import moment from "moment-timezone";
import ProfilePicture from "../ProfilePicture";
import FastAnswers from "../FastAnswers";
import Flag from "react-world-flags";
import parsePhoneNumber from "libphonenumber-js";
import logo from "../../images/Chatty_Logo02.png";
import UserModal from "../UserModal/UserModal";
import AudioPlayer from "../AudioPlayer";
import { ImageModal } from "../ImageModal";
import { AiOutlineReload } from "react-icons/ai";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useAuth0 } from "@auth0/auth0-react";
import MessageInput from "../MessageInput/MessageInput";

type Content = {
  body: string
}

interface Message  {
  content : Content
  created_at : Date,
  id : string,
  is_incoming_message: boolean,
  status: 'FAILED' | 'READ' | 'DELIVERED' | 'SENT' | 'WAITING' | 'RECEIVED' | 'SENT_TO_META', //Ver que tipos vienen
  referral: null,
  subtype: null,
  type: string,
  update_at: Date
  sent_by: string | null
}


type Client = { 
  DNI: string,
  country: string,
  email: string,
  has_photo: boolean,
  name: string,
  waid: string,
  photo: string
}

const MyInbox: React.FC<MyInboxProps> = ({
  inboxToggle,
  user: userId,
  emojiDropdownIsOpen,
  setEmojiDropdownIsOpen,
  bgColor,
  setFastAnswersIsOpen,
  fastAnswersIsOpen,
  botDropdownIsOpen,
  setBotDropdownIsOpen,
  setUserModalIsOpen,
  userModalIsOpen,
  countrySelectIsOpen,
  setCountrySelectIsOpen,
  setTickerIdSelectIsOpen,
  tickerIdSelectIsOpen,
  setRefetchPreview,
  setUser
}) => {
  const { isModalOpen, setModalOpen, clientUser, setClientUser, agent, context, setAgent , setContext } =
    useContext(userContext) as any;

  const [message, setMessage] = useState<string>("");
  const [messageAudio, setMessageAudio] = useState()
  const [sendLoading, setSendLoading] = useState<boolean>(false)
  const [messagesCache, setMessagesCache] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<string | boolean>(false)
  const [messages, setMessages] = useState<any>([]);
  const [clientData, setClientData] = useState<Client>({
      DNI: '',
      country: '',
      email: '',
      has_photo: false,
      name: '',
      waid: '',
      photo: ''
  })
  const [conversationType, setConversationType] = useState<string>('')
  const [showMessageCache, setShowMessageCache] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState<boolean>(!!clientUser.bot_enabled);
  const [loading, setLoading] = useState(true);
  const { logout, user } = useAuth0();

  const [size, setSize] = useState(20);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const phoneData = parsePhoneNumber(`+${clientUser?.phone_number}`);

  const currentTimestamp = Date.now();

  const {
    data: allMessages,
    isLoading: loadingMessages,
    refetch: refetchMessages,
  } = useQuery({
    queryFn: () =>
    getConversation(
      agent.id,
      clientUser.conversation_id,
      context
    ),
    queryKey: ["getMessage", clientUser, size],
    enabled: !!clientUser.person_id && !fastAnswersIsOpen,
    keepPreviousData: false,
    cacheTime: 0,
    onSuccess(data) {
      if (data.messages.length > messages.length) {
        setMessagesCache([]);
        setShowMessageCache(false);
      }
      setConversationType(data.area)
      setClientData(data.client)
      let messagesReverse = data.messages.reverse()
      setMessages(messagesReverse);
    },
  });

  const switchBot = () => {
    if (isToggled === false) {
      setModalOpen({ ...isModalOpen, bot_on: true });
    }

    if (isToggled === true) {
      setModalOpen({ ...isModalOpen, bot_off: true });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value)
    if(event.target.value == "/") {
      setFastAnswersIsOpen(true)
    }else{
      const text = event.target.value
      setMessage(text);
      if (textareaRef.current && text.length > 44) {
        auto_grow(textareaRef)
      } else if (textareaRef.current && text.length < 44){
        textareaRef.current.style.maxHeight = `46px`;
      }

    }
  };

  function auto_grow(textareaa: React.RefObject<HTMLTextAreaElement>) {
    if(textareaa.current){
      textareaa.current.style.height = "5px";
      textareaa.current.style.height = (textareaa.current.scrollHeight) + "px";
    }
  }

  useEffect(() => {
    auto_grow(textareaRef)
  }, [message])

  const handleKeyDown = (event: any) => {
    
    if(message.length <= 1) {
      console.log('slash: ', event)
      // if(event.code === "Slash" && event.key === "/") {
      //   setFastAnswersIsOpen(true)
      // }
    }
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        if (message) {
          handleSendMessage(message);
          setMessage("");
          //@ts-ignore
        } else {
          console.log('message vacio')
        }
      }
    }
  };
  
  //@ts-ignore
  function Msg({type}){
    //@ts-ignore
    return (
    <div className="container-toast-notification">
      <span>{`Conversación ${
          type === 'block' ?
          'bloqueada' :
          type === 'archived' ?
          'archivada' :
          type === 'desassign' ?
          'desasignada' :
          'asignada'
        }`}
      </span>
      {type === 'block' &&  <button className="btn-undo" onClick={() => handleModify(type === 'block' ? 'unblock' :'desassign')}>Deshacer</button>} 
      {type === 'archived' &&  <button className="btn-undo" onClick={() => handleModify('unarchived')}>Deshacer</button>} 
      {type === 'assign' && (
        <>
          <button className="btn-undo" onClick={() => handleModify(type === 'block' ? 'unblock' :'desassign')}>Deshacer</button>
          <button className="btn-undo" onClick={() => gotToChat()}>Ir al chat</button>
        </>
      )}

    </div>
    )
  }
  const gotToChat = () => {
    setContext('agent');
    window.localStorage.setItem("context", 'agent');
    window.localStorage.setItem('infoChat', JSON.stringify(clientUser))
    console.log('clientUser: ', clientUser)
    // setUser(clientUser.conversation_id)
    setTimeout( () => {
      console.log('abro chat')
      refetchMessages()
    }, 1000 )
  }
  const notify = (type:string) => {
    toast(<Msg type={type} />, {
      //@ts-ignore
      onOpen: ({ type }) => handleModify(type),
      //@ts-ignore
      onClose: () => {
        setRefetchPreview(true)
      }
    });
  }

  const handleModify = async (action:string | boolean) => {
    setShowModal(false)
    try {
        if(action !== 'assign') {
          const result = await modifyChats(agent.id,clientUser.conversation_id,action,context)
          console.log('respuesta: ',result)
          setRefetchPreview(true)
        } else {
          const result = await assignChatsAdmin(agent.id, clientUser.conversation_id,) 
          console.log('respuesta: ',result)
          setRefetchPreview(true)
        }
      } catch (error) {
        toast.error(`Error al ${
          action === 'block' ?
          'bloquear' :
          action === 'archived' ?
          'archivar' :
          action === 'desassign' ?
          'dejar' :
          'asignar'
        } la conversacion`, {hideProgressBar: true});
      }
      finally {
      console.log('refetch desde modify', action)
      if(action !== 'unread') {
        refetchMessages()
      }
      setRefetchPreview(true)
      setClientUser({})
    }
  };

  const containsChineseCharacters = (texto: string) => {
    const regex =
      /[\p{Script=Hani}\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}]+/gu;
    return texto.replace(regex, "");
  };

  const handleSendMessage = async (text:string) => {
    setMessagesCache([...messagesCache, text]);
    setShowMessageCache(true);
    setSendLoading(true)
    try {
      await sendMessage( agent.id, clientUser.conversation_id, text, moment(new Date).format())
      setMessage('')
    }
    catch {
      refetchMessages();
      setSendLoading(false)
    } 
    finally {
      refetchMessages();
      setSendLoading(false)
    }

  }
  useEffect(() => {
    setAgent({
      id: user?.email,
      fullName: user?.name ,
      email : user?.email
    })
    const socket = io(`${process.env.REACT_APP_API_SOCKET_URL}`);
    socket.on(`newMessage`, () => {
      if(clientUser.conversation_id !== null) {
        console.log('get conver desde newMessage')
        refetchMessages();
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if(clientUser.conversation_id !== null) {
      console.log('get conver desde clientUser')
      refetchMessages()
    }
    return () => {
      setMessagesCache([]);
    };
  }, [clientUser]);

  useEffect(() => {
    if(clientUser.conversation_id !== null && !userModalIsOpen) {
      console.log('get conver desde userModalIsOpen')

      refetchMessages()
    }
  }, [userModalIsOpen])

  useEffect(() => {
    setLoading(true);
    setSize(20);
  }, [userId]);

  useEffect(() => {
    return () => {
      setClientUser({});
    };
  }, [size]);

  useEffect(() => {
    return () => {
      setMessagesCache([]);
    };
  }, [clientUser]);

  useEffect(() => {
    setLoading(true);
    setSize(20);
  }, [userId]);

  useEffect(() => {
    console.log('Hay que agregar el audio aca: ',message)
  }, [messageAudio])

  return (
    <div
      className={`${
        inboxToggle ? "flex" : "hidden"
      } h-full md:col-span-4 col-span-full md:flex flex-col justify-between drop-shadow-lg rounded-lg shadow-lg shadow-slate-400`}
    >
      <div
        className={`w-full basis-20 flex justify-between items-center px-6 shadow-lg ${bgColor} py-3.5`}
      >
        <div className="basis-3/4">
          {!clientUser.name ? (
            <Skeleton nullMargin />
          ) : (
            <div className="flex items-center py-1">
              <ProfilePicture
                fullName={containsChineseCharacters(clientUser?.name)}
                size={12}
                fontSize={"xl"}
              />
              <div className="space-y-1 font-medium ml-3">
                <div
                  className="text-[#f9f8f8]"
                >
                <button
                    onClick={async (event) => {
                      event.stopPropagation();
                      setUserModalIsOpen(true)
                    }}
                    className="flex rounded-3xl hover:text-principalColor hover:bg-white hover:rounded-3xl px-2 transition duration-200"
                  >
                    {clientData.name}
                    <HiPencil className="mt-1 mx-1 w-4" />
                  </button>
                  {userModalIsOpen && (
                    <UserModal
                      refetchMessages={refetchMessages}
                      setRefetchPreview={setRefetchPreview}
                      chatId={clientUser.conversation_id}
                      clientData={clientData}
                      setUserModalIsOpen={setUserModalIsOpen}
                      userModalIsOpen={userModalIsOpen}
                      countrySelectIsOpen={countrySelectIsOpen}
                      setCountrySelectIsOpen={setCountrySelectIsOpen}
                      setTickerIdSelectIsOpen={setTickerIdSelectIsOpen}
                      tickerIdSelectIsOpen={tickerIdSelectIsOpen}
                    />
                  )}
                </div>
                <div className="flex text-sm text-principalColor truncate px-2">
                  <Flag
                    code={phoneData?.country}
                    className="w-4 h-4 mr-3 mt-0.5"
                  />
                  +{clientData.waid}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`${!clientUser.phone_number ? "hidden" : "flex"}`}>
          <div
            className=" mx-1  hover:bg-[#3b4a54] hover:cursor-pointer trainsition-all duration-200 rounded-full p-2"
            onClick={async () => {
              refetchMessages();
              setRefetchPreview(true);
            }}
          >
            <AiOutlineReload className="text-[#04fbeb] w-6 h-6" />
          </div>
          <div className={`w-10 h-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl ${conversationType === 'WITH AGENT' ? '' : 'hover:bg-[#3b4a54]'}`}>

            {context === 'admin' ? (
              <SiRocketdotchat
                size={25}
                // className={`text-[#04fbeb]  ${conversationType === 'WITH AGENT' ? 'disable-btn' : ''}`}
                className={`text-[#04fbeb]`}

                title="Tomar chat"
                // onClick={() => setShowModal('assign')}
                onClick={() => notify('assign')}
              />
            ) : (
                <RiChatOffLine
                  size={25}
                  className="text-[#04fbeb]"
                  title="Poner chat como no leido"
                  onClick={() => handleModify('unread')}
                />
            )}
          </div>
            {context === 'agent' && (
              <div className={`w-10 h-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl hover:bg-[#3b4a54]`}>
                <BsPersonFillDown
                  size={25}
                  className="text-[#04fbeb]"
                  title="Transferir a esperando agente"
                  onClick={() => notify('desassign')}
                />
              </div>
            )}

          <div
            className="w-10 h-10 p-2 mx-1 hover:bg-[#3b4a54] hover:cursor-pointer trainsition-all duration-200 rounded-3xl"
            >
             
              <TbArchive
                onClick={() => notify('archived')}
                size={25}
                className={`text-[#04fbeb] ${conversationType === 'ARCHIVED' ? 'disable-btn' : ''}`}
                title="Archivar chat"
              />
          </div>
          <div className={`w-10 h-10 p-2 mx-1 hover:cursor-pointer trainsition-all duration-200 rounded-3xl ${context === 'admin' && conversationType === 'WITH AGENT'  ? '' : 'hover:bg-[#3b4a54]'}`}>
              <TbLockAccessOff 
                size={25}
                title="Bloquear conversación" 
                onClick={() => notify('block')}
                className={`text-[#04fbeb] ${context === 'admin' && conversationType === 'WITH AGENT' ? 'disable-btn' : ''}`}
              />
          </div>
        </div>
      </div>
      <div className="w-full basis-3/4 flex flex-col-reverse px-6 overflow-scroll overflow-x-hidden whitespace-pre-wrap">
        {!clientUser?.phone_number ? (
          <div className="flex justify-center align-middle mb-14">
            <img className=" w-96 h-96" src={logo} />
          </div>
        ) : loadingMessages ? (
          Array.from({ length: 7 }).map((_, i) => <Skeleton key={i} />)
        ) : (
          <div className="flex flex-col-reverse">
            <div className="flex flex-col">
              {messagesCache?.map((messageCache, index) => {
                return (
                  <div
                    key={index}
                    className={` ${
                      messageCache.length ? "flex flex-col" : "hidden"
                    } min-h-fit md:max-w-[100%] max-w-[100%] mt-3 bg-[#25304e] text-white py-1 px-[18px] rounded-3xl rounded-br-none md:text-base text-xs self-end break-words block`}
                  >
                    {/* <div className="text-sm text-principalColor">
                      {agent.fullName ? agent.fullName : "Nombre de Agente"}
                    </div> */}
                    {messageCache &&
                      messageCache
                        .replace(/\s*\\n\s*/g, "\n")
                        .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                        .map((part: any, index: any) => {
                          if (part.startsWith("*") && part.endsWith("*")) {
                            return (
                              <span key={index} className="font-bold">
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (
                            part.startsWith("_") &&
                            part.endsWith("_")
                          ) {
                            return (
                              <span key={index} className="italic">
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                            if (
                              /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(part)
                            ) {
                              return (
                                <div className="flex flex-col">
                                  <img key={index} src={part} alt="" />
                                  <ImageModal index={index} part={part} />
                                  <p>CAPTION CACHE</p>
                                </div>
                              );
                            } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                              return (
                                <video key={index} controls>
                                  <source src={part} type="video/mp4" />
                                </video>
                              );
                            } else if (/\.(ogg)$/i.test(part)) {
                              return <AudioPlayer audioUrl={part} />;
                            } else {
                              return (
                                <a
                                  key={index}
                                  className="text-principalColor"
                                  href={part}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {part}
                                </a>
                              );
                            }
                          } else if (part === "\n") {
                            return <br key={index} />;
                          } else {
                            return <span key={index}>{part}</span>;
                          }
                        })}

                    <div className="flex text-xs justify-end text-gray-300">
                      {moment().format("LT")}
                      {sendLoading ?
                        <BsFillClockFill size={13} style={{
                          width: '16px',
                          height: '13px',
                          marginLeft: '2px'
                        }}/>
                        
                      :
                        <BsCheck size={18} />
                      }
                    </div>
                  </div>
                );
              })}
            </div>
            {messages?.map((messageItem: Message, index: number, arr: any) => {
              return(
              <div className="w-full flex flex-col globo" key={index}>
                {moment(messageItem?.created_at).format("DD/MM/YYYY") !==
                  moment(arr[index + 1]?.created_at).format("DD/MM/YYYY") && (
                  <div className="flex justify-center text-sm text-white bg-[#212944] m-auto p-2 rounded-lg my-1">
                    {moment(messageItem.created_at).format("dddd DD/MM/YYYY")}
                  </div>
                )}
                <div
                  className={`${messageItem.type === 'central' ? "flex" : "hidden"} justify-center text-sm text-gray-500 bg-gray-100 m-auto px-2 py-1 rounded-lg my-1`}
                >
                  {moment(messageItem.created_at).format("DD/MM/YYYY")}{" "}
                  {messageItem.content.body}
                </div>
                {messageItem.is_incoming_message && (
                  <div className={`items-end mt-3 flex`}>
                    <div className="mr-2">
                      {
                        <ProfilePicture
                          fullName={clientUser?.name}
                          size={7}
                          fontSize={"sm"}
                        />
                      }
                    </div>
                    <div className="min-h-fit md:max-w-[45%] max-w-[65%] border bg-slate-50 py-2 px-[18px] rounded-3xl rounded-bl-none md:text-base text-xs break-words">
                    <p><b className="agent-name-globo">{clientUser.name}</b></p>
                      {messageItem.content.body &&
                        messageItem.content.body
                          .replace(/\s*\\n\s*/g, "\n")
                          .replace(/Descargar\s*-->\s*/, "")
                          .replace(/Caption:\s*/, "")
                          .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                          .map((part: any, index: any) => {
                            if (part.startsWith("*") && part.endsWith("*")) {
                              return (
                                <span key={index} className="font-bold">
                                  {part.slice(1, -1)}
                                </span>
                              );
                            } else if (
                              part.startsWith("_") &&
                              part.endsWith("_")
                            ) {
                              return (
                                <span key={index} className="italic">
                                  {part.slice(1, -1)}
                                </span>
                              );
                            } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                              if (
                                /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(part)
                              ) {
                                return (
                                  <div className="flex flex-col">
                                    <img key={index} src={part} alt="" />
                                    <ImageModal index={index} part={part} />
                                    {//@ts-ignore
                                    messageItem.content.caption && <p className="mt-1">{messageItem.content.caption}</p>}
                                </div>
                                );
                              } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                                return (
                                  <video key={index} controls>
                                    <source src={part} type="video/mp4" />
                                  </video>
                                );
                              } else if (/\.(ogg|mpeg|mp3)$/i.test(part)) {
                                return <AudioPlayer audioUrl={part} />;
                              } else if(part.includes("mp3")) {
                                console.log('Entra por aca', part)
                                return <AudioPlayer audioUrl={part} />;
                              } else {
                                console.log('Entra por aca', part)
                                return (
                                  <a
                                    key={index}
                                    className="text-principalColor"
                                    href={part}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {part}
                                  </a>
                                );
                              }
                            } else if (part === "\n") {
                              return <br key={index} />;
                            } else {
                              return <span key={index}>{part}</span>;
                            }
                          })}

                      <div className="text-xs text-gray-600">
                        {moment(messageItem.created_at).format("LT")}
                      </div>
                    </div>
                  </div>
                )}
                {!messageItem.is_incoming_message && messageItem.type !== 'central' && (

                <div className={`block bg-[#25304e] min-h-fit md:max-w-[100%] max-w-[100%] mt-3 text-white py-1 px-[18px] rounded-3xl rounded-br-none md:text-base text-xs self-end break-words block`}>
                  {/* <div className={`text-sm text-principalColor`}>
                    {messageItem.is_incoming_message
                      ? ''
                      : messageItem?.agent?.name ? messageItem?.agent?.name : "Nombre de Agente"}
                  </div> */}
                  {messageItem.sent_by !== null && (<p><b className="agent-name-globo">{messageItem.sent_by}</b></p>)}

                  {messageItem.content.body &&
                    messageItem.content.body
                      .replace(/\s*\\n\s*/g, "\n")
                      .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                      .map((part: any, index: any) => {
                        if (part.startsWith("*") && part.endsWith("*")) {
                          return (
                            <span key={index} className="font-bold">
                              {part.slice(1, -1)}
                            </span>
                          );
                        } else if (part.startsWith("_") && part.endsWith("_")) {
                          return (
                            <span key={index} className="italic">
                              {part.slice(1, -1)}
                            </span>
                          );
                        } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                          if (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(part)) {
                            return (
                              <div className="flex flex-col">
                                <img key={index} src={part} alt="" />
                                <ImageModal index={index} part={part} />
                                {//@ts-ignore
                                messageItem.content.caption && <p className="mt-1">{messageItem.content.caption}</p>}
                              </div>
                            );
                          } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                            return (
                              <video key={index} controls>
                                <source src={part} type="video/mp4" />
                              </video>
                            );
                          } else if (/\.(ogg|mpeg|mp3)$/i.test(part)) {
                            return <AudioPlayer audioUrl={part} />;
                          } else {
                            return (
                              <a
                                key={index}
                                className="text-principalColor"
                                href={part}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {part}
                              </a>
                            );
                          }
                        } else if (part === "\n") {
                          return <br key={index} />;
                        } else {
                          return <span key={index}>{part}</span>;
                        }
                      })}
                    <div className="flex text-xs justify-end text-gray-300">
                    {moment(messageItem.created_at).format("LT")}
                    {messageItem.status === 'WAITING' || messageItem.status === 'SENT_TO_META' && (<BsFillClockFill size={18} />)}
                    {messageItem.status === 'SENT' || messageItem.status === 'DELIVERED'  && (<BsCheck size={18} />)}
                    {messageItem.status === 'RECEIVED' && (<div><BsCheck2All size={18} /></div>)}
                    {messageItem.status === 'READ' && (<div className="read-icons"><BsCheck2All size={18} /></div>)}
                </div>
                </div>

                )}
                {messageItem.status === 'FAILED' && (
                  <div className="text-red-500 flex self-end items-center text-sm">
                    <div>Error al enviar el mensaje</div>
                    <BiErrorCircle color="red" size={18} />
                  </div>
                )}

              </div>
            )})}
          </div>
        )}
      </div>
      <div
        className={`w-full h-auto basis-24 flex items-center justify-center px-6 my-5 ${
          clientUser.name?.length > 0 ? "" : "pointer-events-none"
        }`}
      >
        <form
          className={`w-full flex items-center justify-between px-4 rounded-xl border border-slate-400 
          ${clientUser.name?.length > 0 ? '' : 'bg-slate-300'}
          ${context === 'admin' ? ' disable-form' : ''}
          `}
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage(message);
          }}
        >
          {emojiDropdownIsOpen ? (
            <div
              className={`absolute max-h-72 overflow-scroll grid overflow-x-hidden bottom-20 grid-rows-auto grid-cols-6 p-1 rounded-2xl rounded-bl-none bg-slate-50 list-none emoji-dropdown-container`}
            >
              {emojiArr.map((emoji, index) => (
                <li
                  className="text-3xl cursor-pointer"
                  key={index}
                  onClick={() => setMessage(message.concat(emoji))}
                >
                  {emoji}
                </li>
              ))}
            </div>
          ) : (
            <div
              className={`absolute max-h-72 overflow-scroll hidden overflow-x-hidden bottom-20 grid-rows-auto grid-cols-6 p-1 rounded-2xl rounded-bl-none bg-slate-50 list-none`}
            >
              {emojiArr.map((emoji, index) => (
                <li
                  className="text-3xl cursor-pointer"
                  key={index}
                  onClick={() => setMessage(message.concat(emoji))}
                >
                  {emoji}
                </li>
              ))}
            </div>
          )}
                      <BsEmojiHeartEyesFill
              size={28}
              className={`cursor-pointer text-slate-500 `}
              onClick={async (event) => {
                event.stopPropagation();
                setEmojiDropdownIsOpen(true);
              }}
            />
          {/* {!handleSendMessage.isLoading ? (
            <BsEmojiHeartEyesFill
              size={28}
              className={`cursor-pointer text-slate-500 `}
              onClick={async (event) => {
                event.stopPropagation();
                setEmojiDropdownIsOpen(true);
              }}
            />
          ) : (
            <Spinner
              aria-label="Success spinner example"
              color="success"
              className="mr-52"
            />
          )} */}

          <textarea
            ref={textareaRef}
            // value={!handleSendMessage.isLoading ? message : ''}
            value={message}
            className={`text-area-message w-full border-0 focus:border-0 focus:outline-none px-5 disabled:cursor-not-allowed bg-inherit disabled:bg-slate-300 overflow-y-auto h-auto`}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <IoMdSend
            size={28}
            className={`cursor-pointer text-[#1a2238]`}
            onClick={(event) => {
              if (message) {
                event.preventDefault();
                handleSendMessage(message);
                setMessage('')
              }
            }}
          />
        </form>
        {context === 'agent' && (<div className="flex justify-center p-2 ml-3 mr-1">
          <FastAnswers
            updateInput={setMessage}
            fastAnswersIsOpen={fastAnswersIsOpen}
            setFastAnswersIsOpen={setFastAnswersIsOpen}
            handleSendMessage={sendLoading}
          />
        </div>)
        }
      </div>
      <ConfirmationModal show={showModal} action={handleModify} onClose={setShowModal}/>
    </div>
  );
};

export default MyInbox;
