import { axiosConfig } from "./axios.config";

const getTable = async (table) => {

  const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/configuration/table_${table}`
  );
  return res.data;
};

const actionTable = async (type, body, table, id) => {
	const url = `${process.env.REACT_APP_API_NEW_URL}/configuration/table_${table}`

	if(type === 'edit') {
		const res = await axiosConfig.put(`${url}/${id}`, body);
		return res.data;
	} else if(type === 'add') {
		const res = await axiosConfig.post(url , body);
		return res.data;
	} else {
		const res = await axiosConfig.delete(`${url}/${id}`, body);
		return res.data;
	}
}

const dropdownTable = async (table) => {
	const url = `${process.env.REACT_APP_API_NEW_URL}/dropdowns_for_tables/dropdowns/${table}`
	const res = await axiosConfig.get(`${url}`);

	return res.data;
}

export {getTable, actionTable, dropdownTable}