import React, { useContext, useEffect, useState } from "react";
import userContext from "../utils/userContext";
import Tags from "./Tags/Tags";
import { Tab } from "./Tab";
import { useQuery } from "@tanstack/react-query";
import { getConversation } from '../services/Messages.service'
import { getAllTags } from "../services/Preview.service";
import { context } from "../interfaces";

interface Props {
  inboxToggle: boolean;
  setInboxToggle: React.Dispatch<React.SetStateAction<boolean>>;
  user: string;
  bgColor: string;
  setTagDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagDropdownIsOpen: boolean;
  isPanelDisabled: boolean;
  setRefetchPreview: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPreview: any;
}

const MyPanel: React.FC<Props> = ({
  user: userId,
  setTagDropdownIsOpen,
  tagDropdownIsOpen,
  bgColor,
  isPanelDisabled,
  setRefetchPreview, 
  refetchPreview,
  inboxToggle
}) => {
  const {agent, context, clientUser} = useContext(userContext) as context;

  const [activeTagsTab, setActiveTagsTab] = useState<number>(0);
  const [tags, setTags] = useState([])
  const [highlights, setHighlights] = useState([])
  const { 
    data: conversationData,
    refetch: refetchData,
   } = useQuery({
    queryFn: () => getConversation(agent.id, clientUser.conversation_id, context),
    keepPreviousData: false,
    enabled: !!clientUser.conversation_id,
    onError(data) {
      console.log('los: ', data)
    },
    onSuccess(data) {
      setHighlights(data.highlights)
      setTags(data.tags)
    }
  });

  useEffect(() => {
    if(clientUser?.conversation_id) {
      console.log('get conver desde panel')
      refetchData()
    }else {
      setTags([])
      setHighlights([])
    }
  }, [clientUser])

  useEffect(()=> {
    console.log('cambio de mensaje')
  }, [clientUser])

  return (
    <>
      {isPanelDisabled ? (
        <div
          className={`flex h-full md:col-span-2 col-span-full md:flex flex-col gap-2 drop-shadow-lg`}
        >
          <div
            className={`flex justify-between items-center shadow-lg ${bgColor} h-[84px] `}
          ></div>
          <Tab highlights={[]} disabled={true} activeTagsTab={activeTagsTab} />
        </div>
      ) : (
        <div className={`flex h-full md:col-span-2 col-span-full md:flex flex-col gap-2 drop-shadow-lg`}>
          <div
            className={`flex justify-between items-center shadow-lg ${bgColor} h-[84px] `}
          ></div>
            <>
              <Tags
                conversationTags={tags}
                tagDropdownIsOpen={tagDropdownIsOpen}
                setTagDropdownIsOpen={setTagDropdownIsOpen}
                setActiveTagsTab={setActiveTagsTab}
                setRefetchPreview={setRefetchPreview}
                />
              <Tab 
                highlights={highlights}
                refetchData={refetchData}
                activeTagsTab={activeTagsTab} 
                />
            </>
        </div>
      )}
    </>
  );
};

export default MyPanel;
