import { axiosConfig } from "./axios.config";

const getTabs = async (company) => {

  const res = await axiosConfig.get(`${process.env.REACT_APP_API_ANALYTICS}tabs/${company}`
  );
  return res.data;
};

const getTemplate = async (company, tab) => {

    const res = await axiosConfig.get(`${process.env.REACT_APP_API_ANALYTICS}tab-template/${company}/${tab}`
    );
    return res.data;
  };

const getInfoLayout = async (company, tab) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_ANALYTICS}tab_data
    `, 
    // {
    //     "empresa": "GT",
    //     "tab": "ChatsAssignedToAgentMetric",
    //     "filters": {
    //       "globalDateFilter": {
    //         "startDate": "2023-01-01",
    //         "endDate": "2023-03-31"
    //       }
    //     }
    //   }
    {
        "empresa": company,
        "tab": tab,
        "filters": {
            "globalDateFilter": {
            "startDate": "2023-01-01",
            "endDate": "2023-03-31"
            }
        }
    }
    );
    return res
}
export {getTabs, getTemplate, getInfoLayout}