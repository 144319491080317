import { axiosConfig } from "./axios.config";

const assignChats = async (agent_id, quantity ) => {
  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/assign_chats_to_agent`,
  {
    "agent_id": agent_id,
    "quantity_to_assign": quantity
  }
  );
  return res.data;
};

const desassignChats = async (agent_id ) => {
	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/desassign_all_chats`,
	{
		"agent_id": agent_id,
	}
	);
	return res.data;
};

const desassignChatsSelected = async (agent_id, chatSelected ) => {
	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/desassign_n_chats`,
	{
	"agent_id": agent_id,
    "chat_ids_to_desassign": chatSelected
	}
	);
	return res.data;
};

const assignChatsSelected = async (agent_id, chatSelected ) => {
	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/assign_n_chats`,
	{
		"agent_id": agent_id,
    	"chat_ids_to_assign": chatSelected
	}
	);
	return res.data;
};

const modifyChats = async (agent_id, chat_id, action, type ) => {

	const body = type === 'agent' ? { "agent_id": agent_id, "chat_id": chat_id, "action": action } : {"chat_id": chat_id, "action": action}

	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/modify_chat`, body
	);
	return res.data;
};

const assignChatsAdmin = async (agent_id, chat_id ) => {
	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/admin/assign_chat`,
	{
		"agent_id": agent_id,
		"chat_id": chat_id,
	}
	);
	return res.data;
};



export { assignChats, desassignChats, desassignChatsSelected, modifyChats, assignChatsAdmin, assignChatsSelected}