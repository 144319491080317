import React, { useEffect, useState, useRef } from "react";
import { MdOutlineMarkChatUnread } from "react-icons/md";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
// import { getAllTags } from "../../api";
import { getAllTags } from "../../services/Preview.service";
import { GiBasket } from "react-icons/gi";
import { BsListUl } from "react-icons/bs";
import { AiFillMessage, AiOutlineMessage, AiFillCheckSquare, AiOutlinePlus } from "react-icons/ai";
import { Button, Spinner } from "flowbite-react";
import { BsTags, BsFillCheckCircleFill } from "react-icons/bs";
import Tag from "../../interfaces/tag.interface";
import './FilterTagsDropdown.css'

interface Props {
  conversationId: number;
  setTagFilterIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagFilterIsOpen: boolean;
  setConversationsFilterByTag: any;
  searchValue: any;
  setSearchValue: any;
  tagSelectedComplete: any;
  setTagSelectedComplete: any;
  setFilterSelection: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUnread: React.Dispatch<React.SetStateAction<boolean>>;
  showUnread: boolean;  
  expandSearch: React.Dispatch<React.SetStateAction<boolean>>;
  openSearch: boolean;
  filterSelection: boolean;
}
const FilterTagsDropdown: React.FC<Props> = ({
  conversationId,
  tagFilterIsOpen,
  setTagFilterIsOpen,
  setConversationsFilterByTag,
  searchValue,
  setSearchValue,
  tagSelectedComplete,
  setTagSelectedComplete,
  setFilterSelection,
  filterSelection,
  setShowUnread,
  showUnread,
  openSearch,
  expandSearch
}) => {
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [openSlide, setopenSlide] = useState<boolean>(false); 

  const dropdown = useRef<HTMLDivElement>(null)
  const $ref = useRef<HTMLDivElement>(null)
  const inputTags = useRef<HTMLInputElement>(null)
  // let filterTickers = (array: Tag[]) => {
  //   if (Array.isArray(array)) {
  //     return array.filter((tag) => tag.deleted_at === null);
  //   }
  //   return allTags;
  // };

  // const tagsWithoutTicker = filterTickers(allTags);

  const filterUnread = (event: any) => {
    event.stopPropagation();
    setShowSpinner(true);
    setTagSelectedComplete([]);
    setFilterSelection(false)
    setShowUnread(!showUnread);
  };

  const lastButtonAction = (e: any) => {
      if(openSearch) {
        expandSearch(false)
      } else {
        e.stopPropagation();
        setTagFilterIsOpen(!tagFilterIsOpen);
      }
  }

  useEffect(() => {
    if(tagFilterIsOpen) {
      if(inputTags.current){
        inputTags.current.focus()
      }
      console.log('focus input')
    }
  }, [tagFilterIsOpen])

  useEffect(() => {

    if (showSpinner) {
      const timeout = setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [showSpinner]);

  useEffect(() => {
    getAllTags().then((data) => {
      setAllTags(data);
    });
  }, [conversationId]);
 
  useEffect(()=> {
    document.addEventListener('mousedown',closeOpenMenus)
    return ()=> {
      document.removeEventListener('mousedown',closeOpenMenus)
    }
  })

  const closeOpenMenus = (e:any)=>{
    if(dropdown.current && openSlide && !dropdown.current.contains(e.target)){
      setopenSlide(false)
    }
  }

  const activeSelectionMode = () => {
      removeFilters('selection')
  }

  const removeFilters = (type:string) => {
    if(type === 'selection') {
      // setShowUnread(false)
      setTagSelectedComplete([]);
      setFilterSelection(true)
    }
    else if(type === 'tag') {
      setFilterSelection(false)
      setShowUnread(false)
    }
    else if(type === 'all'){
      setFilterSelection(false)
      setShowUnread(false)
      setTagSelectedComplete([]);
    }
    setSearchValue("");
    setShowSpinner(false)
  }

  useEffect( ()=> {
    
    if(openSearch) {
      removeFilters('all')
    }
  }, [openSearch])

  return (
    <div className={`${openSearch ? 'expand' : ''} relative items-center justify-end align-middle z-10 containerFilterBtn`} ref={$ref}>
      <div className="flex items-center justify-end align-middle" >
        <button className='bg-principalColor flex justify-center align-center btnFilter disable'>
          <BsListUl/>
        </button>
        <button
          className={`${showUnread ? 'active' : ''} bg-principalColor flex justify-center align-center btnFilter`}
          onClick={filterUnread}
          title="Filtrar por leídos"
        >
          <AiOutlineMessage />
        </button>
        <button
          className={`${filterSelection ? 'active' : ''} bg-principalColor flex justify-center align-center btnFilter`}
          onClick={() => activeSelectionMode()}
          title="Seleccionar conversaciones"
        >
          <AiFillCheckSquare />
        </button>
        <button
          className={`${tagFilterIsOpen ? 'active' : ''} bg-principalColor flex justify-center align-center btnFilter`}
          onClick={(event) => lastButtonAction(event)}
          title={`${openSearch ? 'Mas filtros' : 'Filtrar por tags'}`}
        >
          {openSearch ? (
            <AiOutlinePlus />
          ) : (
            <BsTags />
          )}
        </button>
      
      </div>
      <div className="ml-auto mr-auto w-full">
        <div className="mx-2 relative">
          {tagFilterIsOpen ? (
            <div className="absolute rounded-2xl border-b-4 right-0 top-full mt-2 w-48 max-h-72 overflow-hidden tags-filter-container">
              <div className="shadow-md active:shadow-xl transition duration-300 ease-in-out transform hover:shadow-lg border-solid border-gray-200 border-2 bg-white rounded-t-2xl overflow-y-auto">
                <input
                  type="text"
                  className="w-full py-2 px-3 border-gray-300 rounded-t-2xl focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-transparent"
                  placeholder="Buscar tags"
                  ref={inputTags}
                  value={searchValue}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement> | any
                  ) => {
                    console.log('este')
                    setSearchValue(event.target.value);
                  }}
                />
                <Button
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    top: "13px",
                    right: `6px`,
                    backgroundColor: "#d3dbd3",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    setSearchValue("");
                  }}
                >
                  <GiBasket className="text-black w-4 h-4" />
                </Button>
                <div
                  className="bg-red-300 hover:bg-red-300"
                  onClick={async (event) => {
                    event.stopPropagation();
                    setTagSelectedComplete([]);
                    setSearchValue("");
                    setTagFilterIsOpen(false);
                    setConversationsFilterByTag([]);
                    removeFilters('tag')
                  }}
                >
                  <DropdownItem key={"clear"} className="justify-center">
                    <div className="flex justify-center align-middle ">
                      <button className="w-full">Limpiar</button>
                    </div>
                  </DropdownItem>
                </div>
                <div className="dropdown-tags">
                  {searchValue.length !== 0
                    ? Array.isArray(allTags) &&
                    allTags
                        ?.filter((tag) =>
                          tag.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .filter(
                          (tag) =>
                            !tagSelectedComplete
                              .map((t: any) => t.id)
                              .includes(tag.id)
                        )
                        .map((t, i) => (
                          <div
                            key={i}
                            onClick={async (event) => {
                              event.stopPropagation();
                              setTagSelectedComplete([
                                ...tagSelectedComplete,
                                t,
                              ]);
                              removeFilters('tag')
                              setSearchValue("");
                              setTagFilterIsOpen(false);
                            }}
                          >
                            <DropdownItem key={t.id} className="justify-center">
                                <button className="w-full">{t.name}</button>
                            </DropdownItem>
                          </div>
                        ))
                    : Array.isArray(allTags) &&
                    allTags
                        ?.filter(
                          (tag) =>
                            !tagSelectedComplete
                              .map((t: any) => t.id)
                              .includes(tag.id)
                        )
                        .map((t, i) => (
                          <div
                            key={i}
                            onClick={async (event) => {
                              event.stopPropagation();
                              setTagSelectedComplete([
                                ...tagSelectedComplete,
                                t,
                              ]);
                              removeFilters('tag')
                              console.log('agrego')
                              setSearchValue("");
                              setTagFilterIsOpen(false);
                            }}
                          >
                            <DropdownItem key={t.id} className="justify-center">
                              <div className="flex justify-center align-middle">
                                <button className="w-full">{t.name}</button>
                              </div>
                            </DropdownItem>
                          </div>
                        ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterTagsDropdown;
