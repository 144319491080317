import { useState , useContext, useEffect} from "react";
import userContext from "../utils/userContext";

import { TabProps } from "../interfaces/tabProps.interface";
import { Highlight } from "./Highlight";
import { Note } from "./Note";

export const Tab: React.FC<TabProps> = ({ disabled, activeTagsTab, highlights, refetchData }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const {context , clientUser} = useContext<any>(userContext);

  useEffect(() => {
    console.log('clientUser tab')
    setShowForm(false)
  }, [clientUser])

  return (
    <div className="flex flex-col h-4/6 overflow-hidden">
      <div
        className={`border-b shadow-md pb-2 ${
          activeTagsTab?.length > 4
            ? "mt-28"
            : activeTagsTab?.length === 4
            ? "mt-12"
            : "mt-1"
        }`}
      >
        <p className="text-center font-medium text-xl">
          {showForm ? "Crear highlight" : "Actividad"}
        </p>
      </div>
      <div className="px-3 overflow-y-scroll h-full flex flex-col">
        {showForm ? <Note setShowForm={setShowForm} refetchData={refetchData}/> : <Highlight  highlightsData={highlights} />}
        {context === 'agent' && (
          <button
            title="crear highlight"
            onClick={() => setShowForm(!showForm)}
            className={`${
              disabled && "pointer-events-none bg-green-500 text-gray-300"
            } absolute bottom-5 right-5 px-3 py-1 rounded-full bg-green-400 m-auto text-lg text-white`}
          >
            {showForm ? "-" : "+"}
          </button>
        )}
      </div>
    </div>
  );
};
