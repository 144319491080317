import { axiosConfig } from "./axios.config";

const searchPreviews = async (agent_id, page_number, tags_filters = [], search_value = '', show_unread = 0, type, area = '') => {
  console.log('area: ', area)
    let result = []
    if(tags_filters.length > 0) {
      result = tags_filters.map(tag => ( tag.id ));
    }
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/assign_previews`,
      {
        "agent_id": agent_id,
        "previews_per_page": 10,
        'area' : area,
        "order_desc": 0,
        "page_number": page_number,
        "unread_filter": show_unread ? 1 : 0,
        "tags_filters": result,
        "search_like": search_value
      }
    );
    return res.data;
};


const getAllTags = async () => {
  const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/agent/get_all_tags`);
  return res.data;
};

const archiveChatsSelected = async (
  agent_id, 
  chatSelected, 
  type,
  area
) => {

  const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/archive_n_chats`,
	{
    "area": area,
		"agent_id": agent_id,
    "chat_ids_to_archive": chatSelected
	}
	);
	return res.data;
}

export { searchPreviews, getAllTags, archiveChatsSelected }