import React, { useEffect, useState, useContext, useMemo, useRef} from "react";
import { getAllTags } from "../../services/Preview.service";
import { addTagToChat, removeTagToChat } from "../../services/Tags.service";
import { Badge, Button } from "flowbite-react";
import { RiCloseFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { GiBasket } from "react-icons/gi";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { useMutation } from "@tanstack/react-query";
import userContext from "../../utils/userContext";
import Tag from "../../interfaces/tag.interface";
import './Tags.css'

type TagData = {
  assign: boolean,
  tag_id: string
}
interface Props {
  conversationTags: Tag[],
  setTagDropdownIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tagDropdownIsOpen: boolean;
  setActiveTagsTab: any;
  setRefetchPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

const Tags: React.FC<Props> = ({
  conversationTags,
  setTagDropdownIsOpen,
  tagDropdownIsOpen,
  setActiveTagsTab,
  setRefetchPreview
}) => {
  const { clientUser, agent, context } = useContext<any>(userContext);

  const [showActiveState, setShowActiveState] = useState<boolean>(true);
  const [showTags, setShowAllTags] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<Tag[]>([])
  const [searchValue, setSearchValue] = useState<string>("");

  const [activeTagsIds, setActiveTagsIds] = useState<string[]>([]);
  const [activeTags, setActiveTags] = useState<any>(conversationTags);
  const inputDropdownSearch = useRef<HTMLInputElement | null>(null)
  const visibleTags = activeTags


  
  const removeTag = (tagId: string) => {  
    // console.log('tags actuales: ', activeTags)
    activeTags.filter((tag: Tag) => {
      console.log('tag:', tag)
      // return  tag.id !== tagId
    })
    setActiveTags((prevTags: Tag[]) =>
      prevTags.filter((tag: Tag) => tag.id !== tagId)
    );
    setActiveTagsIds((prevIds) => prevIds.filter((id: string) => id !== tagId));
  };

  const addTag = (tag: Tag) => {
    const tagExists = activeTags.some((t: Tag) => t.id === tag.id);
    if (!tagExists) {
      setActiveTags((prevTags: Tag[]) => [...prevTags, tag]);
      setActiveTagsIds((prevIds) => [...prevIds, tag.id]);
    }
  };

  const UpdateTags = useMutation({
    mutationFn: (data:TagData) => {
      const { assign , tag_id } = data
      if(assign) {
        return addTagToChat(agent.id ,clientUser.conversation_id, tag_id ).finally( () => {
          console.log('Update preview')
          setRefetchPreview(true)
        })
      } else {
        return removeTagToChat(agent.id ,clientUser.conversation_id, tag_id ).finally( () => {
          console.log('Update preview')
          setRefetchPreview(true)
        })
      }
    }
  })

  const unactiveTags = useMemo(() => {
    return allTags.filter((tag:Tag) => {
      if (Array.isArray(activeTags) && activeTags.length) {
        const matchingTag = activeTags.find(
          (conversationTag) => conversationTag.id === tag.id
        );
        return !matchingTag || matchingTag.is_active === 0;
      }
      return allTags;
    });
  }, [conversationTags, activeTags, addTag, removeTag]);

  const filteredUnactiveTags = useMemo(() => {
    return unactiveTags.filter((tag:Tag) => !activeTags?.includes(tag.id));
  }, [unactiveTags, activeTagsIds, activeTags]);

  useEffect(() => {
    setActiveTags(conversationTags);
    setActiveTagsTab(conversationTags);
  }, [conversationTags]);

  useEffect(()=> {
    console.log('activeTags: ', activeTags)
  }, [activeTags])

  useEffect(()=> {
    console.log('cambio: ', clientUser)
    setActiveTags([])
  }, [clientUser])

  useEffect(() => {
    getAllTags().then((data) => {
      setAllTags(data);
    });
  },[])

  useEffect ( ()=> {
    if(tagDropdownIsOpen) {

      if(inputDropdownSearch.current) {
        inputDropdownSearch.current.focus()
      }
    }
  }, [tagDropdownIsOpen])

  return (
    <div
      className={`${ context ==='admin' && "pointer-events-none"
      } ml-auto mr-auto w-full h-1/6`}
    >
      <h3 className="flex text-gray-500 tracking-wide ml-3 text-lg">
        Etiquetas
      </h3>
      <div className="mx-2">
        {activeTags?.length && (
          <div
            className="mr-2"
            onClick={(event) => {
              event.stopPropagation();
              setTagDropdownIsOpen(true);
            }}
          >
              <div
                onClick={() => setTagDropdownIsOpen(true)}
                className="grid grid-cols-3 cursor-pointer"
              >
                {visibleTags?.map((t: any) => (
                      <div
                        onClick={() => setTagDropdownIsOpen(true)}
                        className="text-white flex p-2 rounded-md m-2 cursor-pointer ease-in-out transform items-center justify-center align-middle"
                        style={{
                          backgroundColor: t.color,
                        }}
                        key={t.id}
                      >
                            <div
                              className="w-full overflow-hidden cursor-default text-center text-xs text-ellipsis whitespace-nowrap justify-center m-0"
                              key={t.id}

                            >
                              {t.name}
                            </div>
                            <Button
                              style={{
                                color: 'white !important',
                                width: "16px",
                                height: "16px",
                                position: "absolute",
                                top: "0px",
                                right: '0px',
                                backgroundColor: showActiveState
                                  ? "transparent"
                                  : "red",
                              }}
                              pill={true}
                            >
                              <RiCloseFill
                                onClick={(event) => {
                                  event.stopPropagation();
                                  UpdateTags.mutate({assign: t.is_active! ,tag_id: t.id})
                                  if (activeTags?.length) {
                                    removeTag(t.id);
                                  }
                                }}
                                style={{
                                  color: "white",
                                }}
                              />
                            </Button>
                      </div>
                ))}
              </div>
          </div>
        )}
        <>
          {!tagDropdownIsOpen && context === 'agent' ? (
            <div className="flex justify-start my-3 align-middle text-center">
              <button
                className="bg-principalColor hover:bg-[#1db9a5] active:bg-[#199c8b] rounded-full p-3 mx-4 my-2 text-gray-100 trainsition-all duration-300"
                onClick={(event) => {
                  event.stopPropagation();
                  setTagDropdownIsOpen(true);
                }}
              >
                <FaPlus />
              </button>
            </div>
          ) : (
            <></>
          )}
        </>

        {tagDropdownIsOpen ? (
          <div className="flex justify-center align-middle my-2">
            <div
              className="rounded-2xl shadow-md active:shadow-xl transition duration-300 ease-in-out transform hover:shadow-lg border-solid border-gray-200 border-2 tags-dropdown-container bg-white"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                width: "250px",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <input
                type="text"
                className="w-full py-2 px-3 rounded-md border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-transparent "
                placeholder="Buscar tagsssss"
                value={searchValue}
                ref={inputDropdownSearch}
                onClick={(event) => event.stopPropagation()}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement> | any
                ) => {
                  event.stopPropagation();
                  setSearchValue(event.target.value);
                }}
              />
              <Button
                className="hover:border-black"
                style={{
                  width: "16px",
                  height: "16px",
                  position: "absolute",
                  top: "13px",
                  right: `6px`,
                  backgroundColor: "#d3dbd3",
                  overflow: "hidden",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setSearchValue("");
                }}
              >
                <div className="flex text-center">
                  <GiBasket className="text-black w-4 h-4" />
                </div>
              </Button>
              {searchValue.length !== 0
                ? filteredUnactiveTags
                    ?.filter((tag: any) =>
                      tag.name.toLowerCase().includes(searchValue.toLowerCase())
                    )
                    .map((t: any) => (
                      <div
                        onClick={async (event) => {
                          event.stopPropagation();
                          console.log(t.is_active, !t.is_active)
                          UpdateTags.mutate({assign: !t.is_active ,tag_id: t.id})
                          addTag({ ...t, is_active: 1 });
                          setSearchValue("");
                        }}
                      >
                        <DropdownItem key={t.id} className="justify-center">
                          <div className="flex justify-center align-middle">
                            <button className="w-full">{t.name}</button>
                          </div>
                        </DropdownItem>
                      </div>
                    ))
                : filteredUnactiveTags?.map((t: any) => (
                    <div
                      onClick={async (event) => {
                        event.stopPropagation();
                        console.log('white')
                        UpdateTags.mutate({assign: true ,tag_id: t.id})
                        addTag({ ...t, is_active: 1 });
                        setSearchValue("");
                      }}
                    >
                      <DropdownItem key={t.id} className="justify-center">
                        <div className="flex justify-center align-middle">
                          <button className="w-full">{t.name}</button>
                        </div>
                      </DropdownItem>
                    </div>
                  ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Tags;
