import React, { useEffect, useState, useRef } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Modal  } from "flowbite-react";
import MultiSelect from './MultiSelect'

import './Form.css'
import { getAllTags } from "../../services/Preview.service";
import { actionTable, dropdownTable } from '../../services/Tables.service'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RESPONSES_MODEL = {
    name: "",
    created_at: new Date(),
    creator: "New Creator",
    split_content: [''],
}

const TAGS_MODEL = {
    name: "",
    color: '',
    icon_name: '',
    description: '',
    category: '',
    is_visible: '',
    created_at: new Date()
}

const PURE_ADS_MODEL = {
    ad_id: "",
    name: '',
    description: '',
    tags_id_and_name: [],
}
const OTHER_SOURCERS_MODEL = {
    name: '',
    description: '',
    tags_id_and_name: [],
}
const TRIGGERS_MODEL = {
    posibilidad: "",
    corte: '',
    other_source_id_and_name: [],
    pure_ad_id_and_name: [],
}
const IMPURE_ADS_MODEL = {
    url: '',
    pure_ad_id_and_name: []
}

type Props = {
    tableSelected: string
    action: string
	selectedRow: any
    tableGetData: () => Promise<void>
    closeModal: (value: React.SetStateAction<boolean>) => void
}

type valueData = {
    id: string, 
    name: string
}

interface TriggersType {
    posibilidad: string,
    corte: string,
    other_source_id_and_name: valueData[],
    pure_ad_id_and_name: valueData[],
}

const FormConfig: React.FC<Props> = ({
    tableSelected,
    action,
	selectedRow,
    tableGetData,
    closeModal
}) => {

    const [formType, setFormType] = useState(tableSelected)
    const [responsesForm, setResponsesForm] = useState(RESPONSES_MODEL)
    const [tagsForm, setTagsForm] = useState(TAGS_MODEL)
    const [pureAdsForm, setPureAdsForm] = useState(PURE_ADS_MODEL)
    const [impureAdsForm, setImpureAdsForm] = useState(IMPURE_ADS_MODEL)
    const [otherSourcersForm, setOtherSourcersForm] = useState(OTHER_SOURCERS_MODEL)
    const [triggersForm, setTriggersForm] = useState<TriggersType>(TRIGGERS_MODEL)
    const [allTags, setAllTags] = useState([])
    const [listDropdown, setListDropdown] = useState([])
    const [listDropdownPureAd, setListDropdownPureAd] = useState([])

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    
    useEffect(() => {
        if(action == 'edit') {
            // Here the edit forms
            if(tableSelected === 'responses') {
                setResponsesForm(selectedRow)
            } else if (tableSelected === 'tags') {
                setTagsForm(selectedRow)
            } else if (tableSelected === 'pure_ads') {
                setPureAdsForm(selectedRow)
            } else if (tableSelected === 'other_sources') {
                setOtherSourcersForm(selectedRow)
            } else if (tableSelected === 'triggers') {
                setTriggersForm(selectedRow)
            } else if (tableSelected === 'impure_ads') {
                setImpureAdsForm(selectedRow)
            }
        } else {
            if(tableSelected === 'responses') {
                setResponsesForm(RESPONSES_MODEL)
            } else if (tableSelected === 'tags') {
                setTagsForm(TAGS_MODEL)
            } else if (tableSelected === 'pure_ads') {
                setPureAdsForm(PURE_ADS_MODEL)
            } else if (tableSelected === 'other_sources') {
                setOtherSourcersForm(OTHER_SOURCERS_MODEL)
            } else if (tableSelected === 'triggers') {
                setTriggersForm(TRIGGERS_MODEL)
            } else if (tableSelected === 'impure_ads') {
                setImpureAdsForm(IMPURE_ADS_MODEL)
            }
        }
    }, [action])

    useEffect(() => {
        dropdownTable('tags').then((data) => {
            console.log('tags: ', data)
            setAllTags(
                data.map((i:any)=> {
                    return {
                        id : i.id,
                        name: i.name
                    }
                })
            );
        });
    },[])

    useEffect(() => {
        if(formType === 'triggers') {
            dropdownTable('other_sources').then((data) => {
                setListDropdown(
                    data.map((i:any)=> {
                        return {
                            id : i.id,
                            name: i.name
                        }
                    })
                );
            });
            dropdownTable('pure_ads').then((data) => {
                setListDropdownPureAd(
                    data.map((i:any)=> {
                        return {
                            id : i.id,
                            name: i.name
                        }
                    })
                );
            });
        }
        else if (formType === 'impure_ads') {
            dropdownTable('impure_ads').then((data) => {
                setListDropdown(data);
            });
        }
    },[formType])

    function auto_grow(textarea: React.RefObject<HTMLTextAreaElement>) {
        if(textarea.current){
            textarea.current.style.height = "5px";
            textarea.current.style.height = (textarea.current.scrollHeight) + "px";
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement >) => {
        if(tableSelected === 'responses') {
            if(e.target.name === 'split_content') {
                const index = parseInt(e.target.id.charAt(e.target.id.length - 1));
                console.log(index)
                let newArr = [...responsesForm.split_content];
                newArr[index] = e.target.value; 
                setResponsesForm({...responsesForm, [e.target.name] : newArr})
                auto_grow(textareaRef)
            }else {
                setResponsesForm({...responsesForm, [e.target.name] : e.target.value})
            }
        } else if(tableSelected === 'tags') {
            setTagsForm({...tagsForm, [e.target.name] : e.target.value})
        } else if(tableSelected === 'pure_ads') {
            setPureAdsForm({...pureAdsForm, [e.target.name] : e.target.value})
        } else if(tableSelected === 'other_sources') {
            setOtherSourcersForm({...otherSourcersForm, [e.target.name] : e.target.value})
        } else if(tableSelected === 'triggers') {
            console.log('triggersForm: ', triggersForm)
            setTriggersForm({...triggersForm, [e.target.name] : e.target.value})
        }
        
    }

    const notify = (text:string) => toast(text);

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()

        let form = tableSelected === 'responses' ? responsesForm : tableSelected === 'tags' ? tagsForm : tableSelected === 'pure_ads' ? pureAdsForm : tableSelected === 'other_sources' ? otherSourcersForm : triggersForm
        if(tableSelected === 'triggers') {
            //@ts-ignore
            if(form.posibilidad === '') {
                notify('Debe completar el campo posibilidad')
                return
            }
            //@ts-ignore
            if(form.corte === '') {
                notify('Debe completar el campo corte')
                return
            }
             //@ts-ignore
            //  if(form.other_source_id_and_name && form.pure_ad_id_and_name) {
            //     notify('No puede seleccionar Other Sources y Pure ad al mismo tiempo')
            //     return
            // }
            // //@ts-ignore
            // if(form.other_source_id_and_name && form.pure_ad_id_and_name) {
            //     notify('Debe seleccionar un Other Source o un Pure Ad')
            //     return
            // }
            if( form.pure_ad_id_and_name.length !== 0) {
                let newArray = []
                //@ts-ignore
                newArray.push(form.pure_ad_id_and_name)
                //@ts-ignore
                form.pure_ad_id_and_name = newArray
            }
            //@ts-ignore
            if(form.other_source_id_and_name.length !== 0) {
                let newArray = []
                //@ts-ignore
                newArray.push(form.other_source_id_and_name)
                //@ts-ignore
                form.other_source_id_and_name = newArray
            }
        }
        console.log('envio: ', form)
        // Enviar el formulario
        actionTable(action, form, tableSelected, selectedRow.id).then( (data) => {
            tableGetData()
            closeModal(false)
        })
    }
	return (
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          className='form-config-container'
        >
          <div>
            {action !== 'delete' && (
                <>
                    {formType === 'responses' && (
                        <>
                            <TextField
                                id="name"
                                label="Name"
                                name='name'
                                onChange={(e) => handleChange(e)}
                                value={responsesForm.name}
                            />
                            {responsesForm.split_content.map((item:string, i) => (
                                <div className="form-array-actions">
                                    <textarea
                                        id={`split_content-${i}`}
                                        key={i}
                                        name="split_content"
                                        // label="Split Content"
                                        onChange={(e) => handleChange(e)}
                                        value={item}
                                        className={'textarea-input'}
                                        // @ts-ignore
                                        ref={textareaRef}
                                    />
                                    <div className="container-multiple-actions-btn">
                                        {responsesForm.split_content.length >= 2 && (
                                            <button type="button" onClick={() => {
                                                let newArr = [...responsesForm.split_content]
                                                newArr.splice(i, 1);
                                                setResponsesForm({...responsesForm, split_content : newArr})
                                            }}>-</button>
                                        )}
                                        {responsesForm.split_content.length == i + 1 && (
                                            <button type="button" onClick={ () => {
                                                let newArr = [...responsesForm.split_content];
                                                newArr[i + 1] = '' 
                                                setResponsesForm({...responsesForm, split_content : newArr})
                                            }}>+</button>
                                        )}
                                    </div>
                                </div>
                            ) )}

                        </>
                    )}
                    {formType === 'tags' && (
                        <>
                            <TextField
                                id="name"
                                label="Name"
                                name='name'
                                onChange={(e) => handleChange(e)}
                                value={tagsForm.name}
                                />
                            <TextField
                                id="color"
                                name="color"
                                label="Color"
                                onChange={(e) => handleChange(e)}
                                value={tagsForm.color}
                                />
                            <TextField
                                id="icon_name"
                                name="icon_name"
                                label="Icon name"
                                onChange={(e) => handleChange(e)}
                                value={tagsForm.icon_name}
                                />
                            <TextField
                                id="description"
                                name="description"
                                label="Descripcion"
                                onChange={(e) => handleChange(e)}
                                value={tagsForm.description}
                                />
                            <TextField
                                id="category"
                                name="category"
                                label="Category"
                                onChange={(e) => handleChange(e)}
                                value={tagsForm.category}
                            />
                        </>
                    )}
                    {formType === 'pure_ads' && (
                        <>
                            <TextField
                                id="name"
                                label="Name"
                                name='name'
                                onChange={(e) => handleChange(e)}
                                value={pureAdsForm.name}
                                />
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                onChange={(e) => handleChange(e)}
                                value={pureAdsForm.description}
                                />
                            <TextField
                                id="ad_id"
                                name="ad_id"
                                label="AD ID"
                                onChange={(e) => handleChange(e)}
                                value={pureAdsForm.ad_id}
                                />
                                {/* @ts-ignore */}
                            {allTags.length > 1 && <MultiSelect data={allTags} defaultSelected={pureAdsForm.tags} onchange={(value:string[]) => setPureAdsForm({...pureAdsForm, tags_id_and_name: value})}/>}
                            
                        </>
                    )}
                    {formType === 'other_sources' && (
                        <>
                            <TextField
                                id="name"
                                label="Name"
                                name='name'
                                onChange={(e) => handleChange(e)}
                                value={otherSourcersForm.name}
                                />
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                onChange={(e) => handleChange(e)}
                                value={otherSourcersForm.description}
                                />
                                {/* @ts-ignore */}
                                {allTags.length > 1 && <MultiSelect data={allTags} defaultSelected={pureAdsForm.tags} onchange={(value:string[]) => setOtherSourcersForm({...otherSourcersForm, tags_id_and_name: value})}/>}
                            
                        </>
                    )}
                    {formType === 'triggers' && (
                        <>
                            <TextField
                                id="posibilidad"
                                name='posibilidad'
                                label="Posibilidad"
                                onChange={(e) => handleChange(e)}
                                value={triggersForm.posibilidad}
                                />
                            <TextField
                                id="corte"
                                name="corte"
                                label="Corte"
                                type="number"
                                onChange={(e) => handleChange(e)}
                                value={triggersForm.corte}
                                />
                            {/* @ts-ignore */}
                            {listDropdown.length > 1 && <MultiSelect 
                                data={listDropdown} 
                                name={'Other Sources'}
                                multiple={false}
                                //@ts-ignore
                                disabled={triggersForm.pure_ad_id_and_name.id ? true : false}
                                onchange={
                                    (value:valueData[]) => (
                                        //@ts-ignore
                                        setTriggersForm({...triggersForm, other_source_id_and_name: value})
                                    )
                                }/>}
                            {/* @ts-ignore */}
                            {listDropdownPureAd.length > 1 && <MultiSelect 
                                data={listDropdownPureAd} 
                                name={'Pure Ad'}
                                //@ts-ignore 
                                disabled={triggersForm.other_source_id_and_name.id ? true : false}
                                multiple={false}
                                onchange={
                                    (value:valueData[]) => (
                                        //@ts-ignore
                                        setTriggersForm({...triggersForm, pure_ad_id_and_name: value})
                                    )
                                }/>}
                        </>
                    )}
                    {formType === 'impure_ads' && (
                        <>
                            <TextField
                                id="url"
                                label="Url"
                                name='url'
                                onChange={(e) => handleChange(e)}
                                value={impureAdsForm.url}
                            />
                            {/* @ts-ignore */}
                            {listDropdown.length > 1 && <MultiSelect multiple={false} name={'Impure Ads'} data={listDropdown} onchange={(value:string[]) => setImpureAdsForm({...impureAdsForm, pure_ad_id_and_name: value})}/>}
                        </>
                    )}
                </>
            )}
            <Modal.Footer className="flex justify-center" style={{paddingBottom: 0}}>
                <Button className="bg-principalColor" type="submit">Confirmar</Button>
                <Button color="failure"  onClick={() => closeModal(false)}>Cancelar</Button>
            </Modal.Footer>
          </div>
        <ToastContainer />
        </Box>
      );
}

export default FormConfig