import { axiosConfig } from "./axios.config";

const addTagToChat = async (agent_id, chat_id, tag_id) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/add_tag`,
    {
      "agent_id": agent_id,
      "chat_id": chat_id,
      "tag_id": tag_id
    }
    );
    
    return res.data;
};

const removeTagToChat = async (agent_id, chat_id, tag_id) => {
    const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/agent/remove_tag`,
    {
      "agent_id": agent_id,
      "chat_id": chat_id,
      "tag_id": tag_id
    }
    );
    
    return res.data;
};




export { addTagToChat, removeTagToChat }