import { useEffect, useState, useContext } from "react";
import NavBar from "../components/NavBar/NavBar";
import './Plantillas.css'
import TextField from '@mui/material/TextField';
import { Button  } from "flowbite-react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { getAreas, getPlantillas, sendPlantillas } from "../services/Plantillas.config";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import userContext from "../utils/userContext";
import { context } from "../interfaces";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Plantilla = {
    name: string,
    text: string
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

const Plantillas: React.FC = () => {
    const [form, setForm] = useState({
        phone: '',
        name: '',
        area: 'Area',
        nameAccordion: ''
    })
    const { agent, context } = useContext(userContext) as context;
    const [area, setArea] = useState([])
    const [plantillas, setPlantillas] = useState<Plantilla[]>([])
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChangeAccordion =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
        console.log('expanded: ', newExpanded ? true : false)
        setForm({...form, nameAccordion : panel})
        if(newExpanded) {
            console.log('block name')
        }
      };

    const getData = async () => {
      try {
        const data = await getAreas()
        setArea(data)
      }
      catch {
        console.log('err')
      }
    }

    const getDataPlantillas = async () => {
      try {
        const data = await getPlantillas()
        setPlantillas(data)
        setExpanded(data[0].name)
        setForm({...form, nameAccordion : data[0].name})
      }
      catch {
        console.log('err')
      }
    }

    useEffect(() => {
        getData()
        getDataPlantillas()
        console.log("agent: ", agent)
    }, [])

    const postTemplates = async () => {
      toast("Enviando plantilla", {
        autoClose: 2000,
      })
      try {
        const data = await sendPlantillas({
          "phone": form.phone, 
          "template_name": form.name === '' ? form.nameAccordion : form.name,
          "area": form.area,
          "agent_id": agent.id
       })
       console.log(data)
       toast.success("Plantilla enviada con exito")
       setForm({
        phone: '',
        name: '',
        area: 'Area',
        nameAccordion: ''
       })
      }
      catch (e){
        //@ts-ignore
        toast.error(e.message)
      }
    }

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log('envio: ', {
          "phone": form.phone, 
          "template_name": form.name === '' ? form.nameAccordion : form.name,
          "area": form.area,
       })
        postTemplates()
        
    }
    const handleChange = (e:React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement> | SelectChangeEvent) => {
        setForm({...form, [e.target.name] : e.target.value})
        if(e.target.name === 'name') {
            setExpanded(false)
        }
    }

    const styles = {
        containerForm : {
            padding: '25px 35px',
        },
        formParent : {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent:'center'
        },
        form : {
            border: '1px solid #cacaca',
            width: 900,
            padding: '20px'
        },
        field: {
            width: '100%', 
            marginBottom: '20px',
        }
    }
    return (
			<div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-white">
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
				<NavBar />
				<div style={styles.containerForm} className='content-form-plantilla'>
                    <div className="title-config">
                        <h2>Enviar plantillas</h2>
                    </div>
                    <div style={styles.formParent}>
                    <form
                        style={styles.form}
                        onSubmit={handleSubmit}           
                    >
                        <TextField
                            id="phone"
                            label="Telefono"
                            name='phone'
                            onChange={(e) => handleChange(e)}
                            value={form.phone}
                            style={styles.field}
                        />
                        <InputLabel id="demo-simple-select-label">Area</InputLabel>
                         <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.area}
                            label="Area"
                            name='area'
                            //@ts-ignore
                            onChange={(e) => handleChange(e)}
                            style={styles.field}
                        >   
                            {area.map((area,i)=> <MenuItem value={area} key={i}>{area}</MenuItem>)}
                        </Select>
                        <div className={expanded ? 'disable-layout-field' : ''}>
                            <TextField
                                id="name"
                                label="Nombre de plantilla"
                                name='name'
                                onChange={(e) => handleChange(e)}
                                value={form.name}
                                style={styles.field}
                            />
                        </div>
                        <div className={form.name !== '' ? 'disable-accordion' : ''} style={{width: '100%', marginBottom: '20px'}} >
                            {plantillas.map((plantilla, i) => {
                                return(
                                    <Accordion style={{background: expanded === plantilla.name ? '#3de8cfa3' : ''}} key={i} expanded={expanded === plantilla.name} onChange={handleChangeAccordion(plantilla.name)}>
                                        <AccordionSummary>
                                            <Typography>{plantilla.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{plantilla.text}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                        <div className="button-submit-layout">
                            <Button className="bg-principalColor" type="submit">Confirmar</Button>
                        </div>
                    </form>
                    </div>
				</div>
			</div>
    )
};

export default Plantillas;