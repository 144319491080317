import { useContext, useEffect } from "react";
import userContext from "../utils/userContext";
import { useNavigate } from "react-router-dom";
import logo1 from "../images/Chatty_Logo01.png";
import logo3 from "../images/GualdAI_Logo03.png";
import OpenNotification from "../components/OpenNotification";
import { useAuth0 } from "@auth0/auth0-react";

const Home: React.FC = () => {
  const { setContext, setAgent } = useContext<any>(userContext);
  const { logout, user } = useAuth0();
  const navigate = useNavigate();


  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const redirection = (value: string) => {
    setContext(value);
    window.localStorage.setItem("context", value);
    navigate("/inbox");
  };

  useEffect( ()=> {
    console.log('user: ', user)
    setAgent({
      id: user?.email,
      fullName: user?.name ,
      email : user?.email
    })
  },[])
    return (
        <div className="min-h-screen w-screen flex font-sans items-center justify-center text-black bg-white">
          <OpenNotification/>
            <div className="md:w-[75rem] w-full md:h-[35rem] h-screen flex flex-col md:flex-row justify-between rounded-lg drop-shadow-lg shadow-lg">
            <div className="md:w-5/12 w-full md:h-full h-46 md:flex items-center justify-center bg-[#1B2238] md:rounded-l-lg md:rounded-tr-none flex-col">
            <img src={logo1} alt="Logo GualdAI" className="h-44"/>
          <div className="flex items-center justify-center my-2">
            <p className="md:block hidden text-lg text-white text-center font-semibold md:text-2xl">
              Chatty
            </p>
          </div>
            <div className="flex items-center mt-1">
            <p className="text-white text-base font-medium mr-2">A product by</p>
            <img className="h-4" src={logo3} alt="Logo GualdAI"/>
            </div>
        </div>
                <div style={{padding: '0px 20px'}} className="flex flex-col justify-center md:w-[59.33%] h-full items-center bg-[white] md:rounded-r-lg md:rounded-bl-none">
                  <div className="flex justify-evenly w-full h-full" style={{flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{marginBottom: 30, display: 'flex', justifyContent: 'space-between'}}>
                      <button 
                        style={{width: '49%'}} 
                        className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300" onClick={() =>redirection("agent")}>
                      Mis chats
                      </button>
                      <button 
                        style={{width: '49%'}} 
                        className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 " onClick={() =>redirection("admin")}>
                      Gestor de chats
                      </button>
                    </div>
                    <div style={{marginBottom: 30, display: 'flex', justifyContent: 'space-between'}}>
                      <button 
                        style={{width: '49%'}} 
                        className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 " onClick={() =>navigate("/config/responses")}>
                      Configurar bot
                      </button>
                      <button 
                        style={{width: '49%'}} 
                        className="bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 " onClick={() =>navigate("/plantillas")}>
                      Plantillas
                      </button>
                    </div>
                    <div className="flex justify-center" >
                      <button  
                        className="w-50 bg-white text-[#1B2238] border-[#1B2238] text-l py-4 px-8 rounded-1xl font-bold w-60 border hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300 " 
                        onClick={() => navigate("/analytics")}>
                      Analytics
                      </button>
                    </div>
                  </div>
                <div className="flex w-full items-end justify-center ">
            <button className="text-[#1B2238] mb-20 rounded-3xl font-bold hover:underline hover:text-[#0f443e]" onClick={handleLogout}>Cerrar Sesión</button>

                </div>
                </div>
            </div>
      </div>
    )
};

export default Home;