import React, { useContext, useState, useRef, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { desassignChatsSelected, assignChatsSelected } from "../../services/ChatsActions.service"
import { archiveChatsSelected } from "../../services/Preview.service"
import { BsXLg } from "react-icons/bs";
import { AiOutlineCheckSquare, AiOutlineArrowRight } from "react-icons/ai";
import { Conversation } from "../Conversation";
import {
  context,
  PreviewInterface,
  ConversationsProps,
} from "../../interfaces";
import userContext from "../../utils/userContext";
import OpenModal from "../OpenModal";
import Skeletion from "../Skeleton";
import './Conversations.css'

export const Conversations: React.FC<ConversationsProps> = ({
  isLoading,
  conversations,
  setInboxToggle,
  setUser,
  isPreviousData,
  skeletonRef,
  checkConversations,
  refetchMyConversations,
  searchRef,
  refetch,
  filter,
  isSelectChatOpen,
  setFilterSelection,
}) => {
  const {
    setClientUser,
    agent,
    isAlert,
    setIsAlert,
    isModalOpen,
    setModalOpen,
    context,
  } = useContext(userContext) as context;

  let currentAssignment = 0;
  let currentAtendant = 0;
  const isMyChatPage = checkConversations

  const [checked, setChecked] = useState<any>([]);
  const [isChatSeenMap, setIsChatSeenMap] = useState<{
    [conversationId: string]: number;
  }>({});
  const [openSlide, setopenSlide] = useState<boolean>(false); 
  const dropdown = useRef<HTMLDivElement>(null)

  const selectAll = () => {
    const actualList = [...checked]
    const allSelectedPage = conversations.map((item:PreviewInterface) => item.chat_id)
    const updatedList = actualList.concat(allSelectedPage);
    
    function removeDuplicates(arr:string[]) {
      return arr.filter((item,
        index) => arr.indexOf(item) === index);
      }
    setChecked(removeDuplicates(updatedList))
  }

  const handleLeaveMultipleChats = useMutation(
    () => desassignChatsSelected(
      agent.id, 
      checked),
    {
      onSuccess: (res) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `Dejó ${res} chats correctamente`,
          type: "success",
        });
        refetchMyConversations().finally(() => {
          setChecked([]);
          setClientUser({});
        });
      },
      onError: (err) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de dejar los chats",
          type: "failure",
        });
        setChecked([]);
        setClientUser({});
      },
      onMutate: () => {
        setModalOpen({ ...isModalOpen, leaveSelected: false });
        refetchMyConversations();
      },
    }
  );

  const handleAssignMultipleChats = useMutation(
    () => assignChatsSelected(
      agent.id, 
      checked),
    {
      onSuccess: (res) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `Asigno ${res} chats correctamente`,
          type: "success",
        });
        refetchMyConversations().finally(() => {
          setChecked([]);
          setClientUser({});
        });
      },
      onError: (err) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de asignar los chats",
          type: "failure",
        });
        setChecked([]);
        setClientUser({});
      },
      onMutate: () => {
        setModalOpen({ ...isModalOpen, leaveSelected: false });
        refetchMyConversations();
      },
    }
  );

  const handleRefetch = () => {
    refetch();
  }

  const sortedConversations = conversations.sort((a, b) => {
    if (a.is_archived === 1 && b.is_archived === 0) {
      return 1;
    }
    if (a.is_archived === 0 && b.is_archived === 1) {
      return -1;
    }
    return 0;
  });
  
  useEffect(()=> {
    document.addEventListener('mousedown',closeOpenMenus)
    return ()=> {
      document.removeEventListener('mousedown',closeOpenMenus)
    }
  })
  
  useEffect(() => {
    if(context === 'agent') {
      //@ts-ignore
      if(window.localStorage.getItem('infoChat')) {
        type previousChatType = {
          conversation_id: string,
          name: string,
          img: string,
          phone_number: string,
        }
        //@ts-ignore
        let previousChat:previousChatType = JSON.parse(window.localStorage.getItem('infoChat'))
        console.log(previousChat)
        handleRefetch();
        setInboxToggle(true);
        setUser(previousChat.conversation_id);
        setClientUser({
          name: previousChat.name,
          img: previousChat.img,
          conversation_id: previousChat.conversation_id,
          phone_number: previousChat.phone_number,
          //@ts-ignore
          tags: previousChat.tags,
        });
        window.localStorage.removeItem('infoChat')
        if (context === "agent") {
          setIsChatSeenMap((prevState) => ({
            ...prevState,
            [previousChat.conversation_id]: 1,
          }));
        }
      }
    }
  }, [context])

  const closeOpenMenus = (e:any)=>{
    if(dropdown.current && openSlide && !dropdown.current.contains(e.target)){
      setopenSlide(false)
    }
  }
  
  const asignmeChats = async () => {
    try {
      const data = await assignChatsSelected(agent.id, checked)
      
      console.log('data: ',data)
      setIsAlert({
        ...isAlert,
        isOpen: true,
        title: `Asigno  chats correctamente`,
        type: "success",
      });
      refetchMyConversations().finally(() => {
        setChecked([]);
        setClientUser({});
      });
    }
    catch {
      setIsAlert({
        ...isAlert,
        isOpen: true,
        title: "Ocurrió un error al tratar de asignar los chats",
        type: "failure",
      });
      setChecked([]);
      setClientUser({})
    }
    finally {
      setModalOpen({ ...isModalOpen, leaveSelected: false });
      refetchMyConversations();
    }
  }

  const archiveChats = () => {
    archiveChatsSelected(
      agent.id, 
      checked,
      context,
      filter)
      .then((res) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `${res}`,
          type: "success",
        });
        refetchMyConversations().finally(() => {
          setChecked([]);
          setClientUser({});
        });
      })
      .catch((err) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de archivar los chats",
          type: "failure",
        });
        setChecked([]);
        setClientUser({});
      })
  }
      

  return (
    <>
    {isSelectChatOpen && (
      <div className={`selectionHeader`}>
        <div className='containerBtn'>
          <button className="selectAll" onClick={() => selectAll()} title='Seleccionar todos'>
            <AiOutlineCheckSquare />
          </button>
          <button className="closeSelection" onClick={() => {
            setFilterSelection(false)
            setChecked([])
            }}>
            <BsXLg />
          </button>
        </div>
        <p>{checked.length} seleccionados</p>
        {checked.length > 0 && 
          <button onClick={() => setopenSlide(!openSlide)} className='dropdownBtn' title="Acciones">
            <AiOutlineArrowRight />
            {openSlide && (
              <div ref={dropdown} className='dropdownContainer'>
                {context === 'agent' ? 
                (
                  <button onClick={() => setModalOpen({ ...isModalOpen, leaveSelected: true })}>Dejar chats</button>
                ) :
                (
                  <button onClick={() => asignmeChats()}>Asignarme chats</button>
                )}
                {/* <button>Transferir a agente</button> */}
                <button onClick={() => archiveChats()}>Archivar chats</button>
              </div>
            )}
          </button>
        }
      </div>
    )}


    <div className={`container-messages w-full basis-[80%] flex flex-col overflow-y-scroll px-3`}>
      <OpenModal
        content={`¿Está seguro que desea dejar ${checked.length} chats?`}
        value="leaveSelected"
        btnValue="dejar"
        fn={handleLeaveMultipleChats.mutate}
      />
      <OpenModal btnValue="dejarlos" value="" />
      {isLoading
        ? Array.from({ length: 7 }).map((_, i) => <Skeletion key={i} />)
        : sortedConversations.map(
            (preview: PreviewInterface, index: number) => {
              return (
                <React.Fragment key={index}>
                  {preview?.last_message?.content && (
                    <div
                      className={`conversation-box w-full flex justify-between items-start px-3 py-1.5 cursor-pointer rounded-lg group group-checked:bg-red-400`}
                      key={index}
                      onClick={() => {
                        handleRefetch();
                        setInboxToggle(true);
                        setUser(preview.chat_id);
                        //@ts-ignore
                        setClientUser({
                          name: preview.client.name,
                          img: preview.client.photo,
                          conversation_id: preview.chat_id,
                          phone_number: preview.client.phone_number,
                          tags: preview.tags,
                        });
                        if (context === "agent") {
                          setIsChatSeenMap((prevState) => ({
                            ...prevState,
                            [preview.chat_id]: 1,
                          }));
                        }
                      }}
                    >
                      <Conversation
                        key={index}
                        name={preview.client.name}
                        phoneNumber={preview.client.phone_number}
                        lastMessage={preview.last_message.content}
                        lastMessageTimestamp={preview.last_message.datetime}
                        photo={preview.business.photo}
                        conversation_id={preview.chat_id}
                        checked={checked}
                        setChecked={setChecked}
                        checkConversation={checkConversations}
                        // isChatSeen={isChatSeenMap[preview.chat_id] || preview.is_read_status}
                        isChatSeen={preview.is_read_status}
                        agentFullName={agent.fullName}
                        agentAssigned={preview.agent_id !== null ? preview.agent_id : null}
                        tags={preview.tags}
                        isSelectChatOpen={isSelectChatOpen}
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            }
          )}

      {isPreviousData ? (
        <div ref={skeletonRef}>
          {Array.from({ length: 10 }).map((_, i) => (
            <Skeletion key={i} />
          ))}
        </div>
      ) : // --------- ver esto ---------
      // size < conversations?.count && (
      //   <div className="flex w-full items-center justify-center mt-2">
      //     <Button onClick={() => setSize((prev: number) => prev + 10)}>
      //       Load more
      //     </Button>
      //   </div>
      // )
      null}
    </div>
  </>
  );
};
