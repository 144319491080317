import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner, TextInput } from "flowbite-react";
import { AiOutlineSearch } from "react-icons/ai";
import { GiBasket } from "react-icons/gi";
import { UserModalProps } from "../../interfaces/userModalProps.interface";
import { getCodes, getNames } from "country-list";
import { DropdownItem } from "flowbite-react/lib/esm/components/Dropdown/DropdownItem";
import { updateClientInfo, getClientInfo } from '../../services/Client.service'
import Flag from "react-world-flags";
import ProfilePicture from "../ProfilePicture";
import './UserModal.css'

interface Person {
  whatsapp_name: string;
  email: string;
  documento: number;
  phone_number_to_send: number;
  phone_number_raw: number;
  country_phone: string;
  ticker_id: any;
}

const initialPerson: Person = {
  whatsapp_name: "",
  email: "",
  documento: 0,
  country_phone: "",
  phone_number_to_send: 0,
  phone_number_raw: 0,
  ticker_id: null,
};

export const UserModal: React.FC<UserModalProps> = ({
  setRefetchPreview,
  chatId,
  setUserModalIsOpen,
  userModalIsOpen,
  countrySelectIsOpen,
  setCountrySelectIsOpen,
  setTickerIdSelectIsOpen,
  tickerIdSelectIsOpen,
  refetchMessages,
  clientData
}) => {
  const { name, waid } = clientData;

  const [isLoading, setIsLoading] = useState(false);
  const countryNames = getNames();
  const countryCodes = getCodes();

  const selectedCountryIndex = countryNames.findIndex(
    (name) => name === clientData.country
  );

  const [showModal, setShowModal] = useState<boolean>(false)
  const [person, setPerson] = useState<Person>(initialPerson);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const documentInputRef = useRef<HTMLInputElement>(null);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [countryCodeSelected, setCountryCodeSelected] = useState(
    countryCodes[selectedCountryIndex]
  );
  const [countryNameSelected, setCountryNameSelected] = useState(
    countryNames[selectedCountryIndex]
  );

  const onClose = async () => {
    setCountrySelectIsOpen(false);
    setUserModalIsOpen(false);
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    const searchValue = searchInputRef.current?.value || "";
    setSearchValue(searchValue);
  };

  const handleParentKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const getInfo = async () => {
    try {
      const data = await getClientInfo(clientData.waid)
      const {name, DNI, email} = data
      if(nameInputRef.current && emailInputRef.current && documentInputRef.current) {
       nameInputRef.current.value = name;
       emailInputRef.current.value = email;
       documentInputRef.current.value = DNI;
      }
      setShowModal(true)
    }
    finally {
      console.log('listo')
    }
  }
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const nameValue = nameInputRef.current?.value || "";
    const emailValue = emailInputRef.current?.value || "";
    const documentValue = Number(documentInputRef.current?.value) || 0;
    setIsLoading(true);
    try {
      await setPerson({
        whatsapp_name: nameValue,
        email: emailValue,
        documento: documentValue,
        country_phone: countryNameSelected,
        phone_number_to_send: Number(waid),
        phone_number_raw: Number(waid),
        ticker_id: '1',
      });
    }
    catch(err) {
      console.log('Fallo al modificar contacto: ', err)
    }
    finally {
      refetchMessages()
    }
  };

  useEffect(() => {
    setCountryCodeSelected(countryCodes[selectedCountryIndex]);
    setCountryNameSelected(countryNames[selectedCountryIndex]);
  }, [selectedCountryIndex]);

  useEffect(() => {
    const updateUserModal = async () => {
      await updateClientInfo(chatId, person);
      setIsLoading(false);
      setUserModalIsOpen(!userModalIsOpen);
      setRefetchPreview(true)
    };

    if (isLoading) {
      setTimeout(updateUserModal, 1000);
    }
  }, [isLoading, person]);

  useEffect( () => {
    getInfo()
  }, [])
  
  return (
    <Modal show={showModal} size="2xl" popup={true} onClose={onClose}>
      <Modal.Header
        onClick={async (event) => {
          event.stopPropagation();
        }}
      />
      <Modal.Body
        onClick={async (event) => {
          event.stopPropagation();
        }}
      >
        <div className="flex items-center justify-center py-1">
          <ProfilePicture fullName={name || ""} size={20} fontSize="2xl" />
          <div className="relative inline-flex flex-col justify-center align-middle my-3 mx-4">
            <label className="mb-2 text-black font-medium">
              {clientData?.name}
            </label>
            <div className="flex gap-5">
              <label className="mb-2">
                +{clientData?.waid}
              </label>
              <label className="mb-2">
                {clientData?.DNI || "No registra documento"}
              </label>
            </div>
            <label className="mb-2">
              {clientData?.email || "No registra email"}
            </label>
          </div>
        </div>
        <form onSubmit={handleSubmit} className='modal-form-container'>
          <div className="w-full flex gap-4 flex-col mt-2 justify-center align-middle items-center">
            <div className="flex h-full form-row">
              <button
                className="bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 text-gray-700 flex items-center justify-between w-72 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-1 h-12"
                onClick={(event) => {
                  event.preventDefault();
                  setCountrySelectIsOpen(!countrySelectIsOpen);
                }}
              >
                <Flag code={countryCodeSelected} className="w-6 h-6 mr-2" />
                {countryNameSelected?.slice(0, 28)}
                <svg
                  className="h-5 w-5 text-gray-400 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 14l6-6H4l6 6z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {countrySelectIsOpen ? (
              <div
                className="absolute rounded-2xl border-b-4  mt-0.5 w-56 max-h-72 overflow-hidden tags-filter-container  z-20 top-56 right-64 mr-5"
                onKeyDown={(event) => handleParentKeyDown(event)}
              >
                <div className="shadow-md active:shadow-xl transition duration-300 ease-in-out transform hover:shadow-lg border-solid border-gray-200 border-2 bg-white rounded-t-2xl overflow-y-auto mt-2 ml-2">
                  <input
                    type="text"
                    className="w-full py-2 px-3 border-gray-300 rounded-t-2xl focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-transparent"
                    placeholder="Buscar País"
                    ref={searchInputRef}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        onSearch(event);
                      }
                    }}
                  />

                  {searchValue.length ? (
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (searchInputRef.current) {
                          setSearchValue("");
                          searchInputRef.current.value = "";
                        }
                      }}
                      className="absolute top-5 right-0.5 transform -translate-y-1/2 w-7 h-7 mr-2 flex align-middle justify-center items-center text-center bg-white text-principalColor hover:bg-principalColor hover:text-white rounded-full transition duration-200 ease-in-out"
                    >
                      <GiBasket className="w-4 h-4" />
                    </button>
                  ) : (
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onSearch(event);
                      }}
                      className="absolute top-5 right-0.5 transform -translate-y-1/2 w-7 h-7 mr-2 flex align-middle justify-center items-center text-center bg-white text-principalColor hover:bg-principalColor hover:text-white rounded-full transition duration-200 ease-in-out"
                    >
                      <AiOutlineSearch className="w-4 h-4" />
                    </button>
                  )}
                  <div className="max-h-48 overflow-y-auto">
                    {searchValue.length
                      ? countryNames
                          .filter((c) =>
                            c
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                          .map((country) => {
                            const index = countryNames.indexOf(country);
                            const countryCode = countryCodes[index];

                            return (
                              <div
                                onClick={async (event) => {
                                  event.stopPropagation();
                                  setCountrySelectIsOpen(
                                    !countrySelectIsOpen
                                  );
                                  setCountryCodeSelected(countryCode);
                                  setCountryNameSelected(country);
                                  setSearchValue("");
                                }}
                              >
                                <DropdownItem
                                  key={country}
                                  className="justify-center h-auto w-auto"
                                >
                                  <div className="flex justify-center align-middle">
                                    <Flag
                                      code={countryCode}
                                      className="w-4 h-4 mr-2"
                                    />
                                    <button className="w-full">
                                      {`${country.slice(0, 17)}${
                                        country.length > 15 ? "..." : ""
                                      }`}
                                    </button>
                                  </div>
                                </DropdownItem>
                              </div>
                            );
                          })
                      : countryNames.map((country, index) => (
                          <div
                            onClick={async (event) => {
                              event.stopPropagation();
                              setCountrySelectIsOpen(!countrySelectIsOpen);
                              setCountryCodeSelected(countryCodes[index]);
                              setCountryNameSelected(countryNames[index]);
                              setSearchValue("");
                            }}
                          >
                            <DropdownItem
                              key={country}
                              className="justify-center h-auto w-auto"
                            >
                              <div className="flex justify-center align-middle">
                                <Flag
                                  code={countryCodes[index]}
                                  className="w-4 h-4 mr-2"
                                />
                                <button className="w-full">
                                  {`${country.slice(0, 17)}${
                                    country.length > 15 ? "..." : ""
                                  }`}
                                </button>
                              </div>
                            </DropdownItem>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="form-row">
              <TextInput
                className="w-96 text-input"
                type="text"
                id="name"
                name="name"
                // value={formData.name}
                ref={nameInputRef}
                required
                placeholder="Nombre y Apellido"
              />
            </div>
            <div className="form-row">
              <TextInput
                className="w-96 text-input"
                type="email"
                id="email"
                // value={formData.email}
                name="email"
                placeholder="Correo Electrónico"
                ref={emailInputRef}
              />
            </div>
            <div className="form-row">
              <TextInput
                className="w-96 text-input"
                type="text"
                id="document"
                name="document"
                // value={formData.document}
                placeholder="Documento"
                ref={documentInputRef}
              />
            </div>
            <Button
              className="w-52 bg-principalColor hover:bg-principalColor"
              type="submit"
            >
              {isLoading ? (
                <Spinner aria-label="Default status example" />
              ) : (
                "Guardar"
              )}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
