import { useState , SyntheticEvent, FC, useEffect} from 'react';
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import { getTabs, getTemplate, getInfoLayout } from "../services/Tabs.service";
import './Config.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid,GridRowId, GridRowSelectionModel,  GridToolbar } from '@mui/x-data-grid';

const Analytics: React.FC = () => {
	const params = useParams()
	const [tabsData, setTabsData] = useState([])
	const [loading, setLoading] = useState(true)
	const [value, setValue] = useState("ChatsAssignedToAgentMetric");
	const [layout, setLayout] = useState([])
	const [columnsNames, setColumnsNames] = useState<any[]>([])
	const [dataForTable, setDataForTable] = useState([])

	const handleChange = (event: SyntheticEvent, newValue:string) => {
		setValue(newValue);
	};

	const getInfoToFill = async (company:string, id:string) => {
		const data = await getInfoLayout(company, id)
		//@ts-ignore
		console.log('data: ', data.data)
		//@ts-ignore
		setDataForTable(data.data.mainTable.data)
	}

	const getTemplateLayout = async (value:string) => {
		console.log(value)
		setLoading(true)
		try {
			const data = await getTemplate("GT", value)
			console.log('data layout: ', data)
			getInfoToFill('GT', value)
			setLoading(false)
			//@ts-ignore
			// data.map((item) => {
			// 	console.log('mapping', item.type, item.id)
			// 	if(item.type === 'dataTable') {
			// 		getInfoToFill('GT', item.id)
			// 	}
			// })

			// setLayout(data.data.layout)
		}
		catch {
			setLoading(false)
		}
	}

	const getData = async () => {
		setLoading(true)
		try {
			const data = await getTabs("GT")

			setTabsData(data)
			setLoading(false)

		}
		catch {
			console.log('err')
			setLoading(false)
		}
	}

	useEffect(()=> {
		getData()
	}, [params.table])

	useEffect( () => {
		getTemplateLayout(value)
	}, [value])

	useEffect(()=> {
		if(dataForTable.length) {
		  const columnObj = Object.getOwnPropertyNames(dataForTable[0]).map((item) => {
			return(
			  { field: item, headerName: item.toUpperCase().replace('_', ' '), minWidth: 200, width: 400 }
			)
		  })
		  setColumnsNames(columnObj)
		}
	  }, [dataForTable])

    return (
			<div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-white">
				<NavBar />
				<div style={{padding: '25px 35px'}}>
					<div className="title-config">
						<h2>Analytics</h2>
					</div>
					<div className='tab-config'>
					<Box sx={{ maxWidth: { xs: 480, sm: 980 }, bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="Analytics tabs"
						>
							{tabsData.map((item, i) => <Tab label={item} key={i} value={item}/>)}
						</Tabs>
					</Box>
					</div>
					<div>
					{!loading ? (
						<DataGrid
							rows={dataForTable}
							columns={columnsNames}
							initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 50 },
							}
							}}
							disableColumnFilter
							disableColumnSelector
							disableDensitySelector
							disableRowSelectionOnClick
							slots={{ toolbar: GridToolbar }}
							slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
							}}
							pageSizeOptions={[5, 10]}
							// checkboxSelection
							// rowSelectionModel={selectionModel}
							//@ts-ignore
							// onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
						/>
						) : (
						<div className='loading-table'>
							<CircularProgress color="success" />
						</div>
						)
					}
					</div>
				</div>
			</div>
    )
};

export default Analytics;