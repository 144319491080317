import {useEffect, useState} from 'react';
import { DataGrid,GridRowId, GridRowSelectionModel,  GridToolbar } from '@mui/x-data-grid';
import TabsConfig from '../TabsConfig/TabsConfig';
import { TABELS } from '../../models/table'
import CircularProgress from '@mui/material/CircularProgress';
import ActionsConfig from '../ActionsConfig/ActionsConfig';
import { useParams } from "react-router-dom";

interface Table { 
  content: string 
  id: string
  name: string
  split_content: string[]
}

type Props = {
  data : Table[]
  loading: boolean
  tableGetData: () => Promise<void>
}
const TableConfig: React.FC<Props> = ({data, loading, tableGetData}) => {
	const params = useParams()
  const [columnsNames, setColumnsNames] = useState<any[]>([])
  const [selectedRow, setSelectedRow] = useState<Table>();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  useEffect(()=> {
    if(data.length) {
      const columnObj = Object.getOwnPropertyNames(data[0]).map((item) => {
        return(
          { field: item, headerName: item.toUpperCase().replace('_', ' '), minWidth: 200, width: 400 }
        )
      })
      setColumnsNames(columnObj)
    }
  }, [data])


  const onRowsSelectionHandler = (ids:string[]) => {
      const selectionSet = new Set(selectionModel);
      const result = ids.filter((s) => !selectionSet.has(s));
      const selected = data.filter((row) => result[0] == row.id)

      console.log('result', result)
      console.log('selected', selected)
      setSelectionModel(result);
      setSelectedRow(selected[0])
  };

    return (
      <div style={{ height: 400, width: '100%' }}>
        <TabsConfig data={TABELS}/>
        <ActionsConfig tableSelected={params.table || ''} selectedRow={selectedRow} tableGetData={tableGetData}/>
        {!loading ? (
          <DataGrid
            rows={data}
            columns={columnsNames}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            rowSelectionModel={selectionModel}
            //@ts-ignore
            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          />
        ) : (
          <div className='loading-table'>
            <CircularProgress color="success" />
          </div>
        )
      }
      </div>
    );
  }

export default TableConfig