import React, { useContext, useEffect, useRef, useState } from "react";
import { context, PreviewInterface, MyPreviewProps, Tag } from "../interfaces";
import { HeaderPreview } from "./HeaderPreview";
import { Pagination } from "./Pagination";
import { useQuery } from "@tanstack/react-query";
import { Search } from "./Search/Search";
import { Badge } from "flowbite-react";
import FilterTagsDropdown from "./FilterTagsDropdown/FilterTagsDropdown";
import userContext from "../utils/userContext";
import io from "socket.io-client";
import { searchPreviews } from "../services/Preview.service";
import FilterDropdown from "./FilterDropdown";

//Mis Chats

const MyPreview: React.FC<MyPreviewProps> = ({
  inboxToggle,
  setInboxToggle,
  setUser,
  tagFilterIsOpen,
  setTagFilterIsOpen,
  getChatsIsOpen,
  setGetChatsIsOpen,
  dropChatsIsOpen,
  setDropChatsIsOpen,
  setUserInfoIsOpen,
  userInfoIsOpen,
  refetchPreview,
  setRefetchPreview,
  chatFilterIsOpen,
  setChatFilterIsOpen
}) => {
  const { agent, clientUser, setClientUser, context } = useContext(
    userContext
  ) as context;

  const [filter, setFilter] = useState<string>("WAITING AGENT");
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const searchRef = useRef<HTMLInputElement>(null);
  const skeletonRef = useRef<HTMLDivElement>(null);
  const [openSearch, setOpenSearch] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterDropdownSelected, setFilterDropdownSelected] =
    useState<string>("Esperando agente");
  const [conversationId, setConversationId] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  let timeout: number = 0;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [showUnread, setShowUnread] = useState<boolean>(false);
  const [unreadConversations, setUnreadConversations] = useState<number>(0);
  const [filterSelection, setFilterSelection] = useState<boolean>(false)

  const [conversations, setConversations] = useState<PreviewInterface[]>(
    []
  );
  const [conversationsFilterByTag, setConversationsFilterByTag] = useState<
    PreviewInterface[]
  >([]);
  const [conversationsFilterByTag2, setConversationsFilterByTag2] =
    useState<any>();

  const [tagSelectedComplete, setTagSelectedComplete] = useState<Tag[]>([]);

  const uniqueTagsCompleted = tagSelectedComplete.filter((tag, index) => {
    return tagSelectedComplete.indexOf(tag) === index;
  });

  const descendingOrder = (data:PreviewInterface[]) => {

    const orderedConversations = data
    .filter(
      (conversation: PreviewInterface) =>
        conversation?.last_message?.datetime
    )
    .sort((a: PreviewInterface, b: PreviewInterface) => {
      if (a.last_message?.datetime < b.last_message?.datetime) return 1;
      if (a.last_message?.datetime > b.last_message?.datetime) return -1;
      return 0;
    });
    return orderedConversations
  }

  const {
    isLoading,
    isPreviousData,
    refetch: refetchMyConversations,
  } = useQuery({
    queryFn: () =>
      searchPreviews(
        agent.id, 
        currentPage, 
        tagSelectedComplete, 
        searchRef.current?.value, 
        showUnread === true ? 1 : 0, 
        context,
        context === 'admin' ? filter : ''
      ),
    queryKey: [
      "allUsersData",
      searchRef.current?.value,
      skip,
      tagSelectedComplete,
      showUnread,
    ],
    enabled: !!agent.id,
    keepPreviousData: false,
    onSuccess(data) {
      setConversations(descendingOrder(data.previews));
      setConversationsFilterByTag(descendingOrder(data.previews));
      setCount(data.total_chats);
      setUnreadConversations(data.total_chats_unread);
      console.log('recargo data: ', data)
    },
  });

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_SOCKET_URL}`);
    socket.on(`newMessage`, () => {
      refetchMyConversations();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (skeletonRef.current)
      skeletonRef.current.scrollIntoView({
        behavior: "smooth",
      });
  }, []);

  useEffect(() => {
    const fetchConversations = async () => {
      if (!tagSelectedComplete.length) {
        // await refetchMyConversations();
      } else if (tagSelectedComplete.length) {
        setIsLoadingData(true);
        await searchPreviews(agent.id, currentPage, tagSelectedComplete, searchRef.current?.value, showUnread === true ? 1 : 0, context, context === 'admin' ? filter : '')
          .then((data) => {
            setConversationsFilterByTag2(descendingOrder(data.previews));
            setCount(data.total_chats);
          })
          .finally(() => {
            setIsLoadingData(false);
          });
      }
    };
    fetchConversations();
  }, [tagSelectedComplete, showUnread, currentPage]);

  useEffect(() => {
    setClientUser(clientUser);
  }, []);

  useEffect(() => {
    if (refetchPreview) {
      refetchMyConversations();
      setRefetchPreview(false);
    }
  }, [refetchPreview]);

  useEffect(() => {
    const search=  document.getElementById('search')
    //@ts-ignore
    search.value = ''
    setSearchValue('')
    refetchMyConversations();
    setClientUser({})
  }, [filter]);

  return (
    <div
      className={`${inboxToggle ? "hidden" : "flex"}
        h-full md:col-span-2 col-span-full md:flex flex-col gap-2 drop-shadow-lg z-10`}
    >
      <div>
      <HeaderPreview
        title={context === 'admin' ? "Gestor de chats" : "Mis chats"}
        allowButton={context !== 'admin'}
        bgColor={context == 'admin' ? "bg-[#576080]" : "bg-[#212944]"}
        filterDropdownSelected={context == 'admin' ? filterDropdownSelected : undefined}
        isOpen={chatFilterIsOpen}
        setIsOpen={setChatFilterIsOpen}
        getChatsIsOpen={getChatsIsOpen}
        setGetChatsIsOpen={setGetChatsIsOpen}
        dropChatsIsOpen={dropChatsIsOpen}
        setDropChatsIsOpen={setDropChatsIsOpen}
        userInfoIsOpen={userInfoIsOpen}
        setUserInfoIsOpen={setUserInfoIsOpen}
        refetchMyConversations={context !== 'admin' ? refetchMyConversations : undefined}
      />
      {chatFilterIsOpen && context === 'admin' && (
          <div className="flex justify-center align-middle" style={{zIndex: 99, position: 'relative'}}>
            <FilterDropdown
              refetch={refetchMyConversations}
              setFilter={setFilter}
              setFilterDropdownSelected={setFilterDropdownSelected}
              setDropdownIsOpen={setDropdownIsOpen}
              isOpen={chatFilterIsOpen}
              setIsOpen={setChatFilterIsOpen}
              setShowUnread={setShowUnread}
            />
          </div>
      )}
      </div>

      <div className="px-4 grid grid-cols-9">
      <div style={{display: 'flex', width: '100%'}} className='col-span-12'>
          <Search
            refetch={refetchMyConversations}
            searchRef={searchRef}
            timeout={timeout}
            expandSearch={setOpenSearch}
            openSearch={openSearch}
          />
          <FilterTagsDropdown
            conversationId={conversationId}
            setFilterSelection={setFilterSelection}
            filterSelection={filterSelection}
            tagFilterIsOpen={tagFilterIsOpen}
            setTagFilterIsOpen={setTagFilterIsOpen}
            setConversationsFilterByTag={setConversationsFilterByTag}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            tagSelectedComplete={tagSelectedComplete}
            setTagSelectedComplete={setTagSelectedComplete}
            setShowUnread={setShowUnread}
            showUnread={showUnread}
            expandSearch={setOpenSearch}
            openSearch={openSearch}
          />
        </div>
        <div className="col-span-9 flex justify-center mt-2">
          {uniqueTagsCompleted.map((tag) => (
            <div
              onClick={async (event) => {
                event.stopPropagation();
                setTagSelectedComplete(
                  tagSelectedComplete.filter((tags) => tags.name !== tag.name)
                );
                setSearchValue("");
                setTagFilterIsOpen(false);
                setConversationsFilterByTag([]);
              }}
            >
              <Badge
                className="px-1 text-blue-700 border-blue-700 bg-white my-2 mx-1 align-middle justify-center rounded-full hover:text-red-500"
                style={{
                  width: "25px",
                  height: "25px",
                  border: `2px solid`,
                }}
                title={tag.name}
                key={tag.name}
              >
                {tag.name.slice(0, 2)}
              </Badge>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        setFilterSelection={setFilterSelection}
        filter={filter}
        isSelectChatOpen={filterSelection}
        refetch={refetchMyConversations}
        setSkip={setSkip}
        skip={skip}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalChats={count ? count : 0}
        isLoading={tagSelectedComplete.length ? isLoadingData : isLoading}
        isPreviousData={isPreviousData}
        setInboxToggle={setInboxToggle}
        setUser={setUser}
        skeletonRef={skeletonRef}
        refetchMyConversations={refetchMyConversations}
        checkConversations={true}
        unreadConversations={unreadConversations}
        conversations={
          tagSelectedComplete.length
            ? conversationsFilterByTag2
            : conversationsFilterByTag?.filter((conversationFilter) =>
                conversations.some(
                  (conversation) =>
                    conversationFilter.client.name === conversation.client.name &&
                    conversationFilter.client.phone_number ===
                      conversation.client.phone_number
                )
              )
        }
      />
    </div>
  );
};

export default MyPreview;
