import { Modal} from "flowbite-react";
import React, { useEffect, useState, useContext } from "react";
import { getFastMessages } from "../services/Messages.service";
import { FaBolt } from "react-icons/fa";
import { useRef } from "react";
import userContext from "../utils/userContext";
import { GiBasket, GiConsoleController } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

interface Props {
  updateInput: React.Dispatch<React.SetStateAction<string>>;
  setFastAnswersIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fastAnswersIsOpen: boolean;
  handleSendMessage: boolean;
}

export type FastChat = {
  ID: number;
  name: string;
  body1: string;
  content: string;
};

export const FastAnswers: React.FC<Props> = ({
  updateInput,
  setFastAnswersIsOpen,
  fastAnswersIsOpen,
  handleSendMessage,
}) => {
  const { clientUser, setFastAnswersModal, fastAnswersModal } = useContext<any>(userContext);
  const [fastChatsInfo, setFastChatsInfo] = useState<FastChat[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOptionValue, setSelectedOptionValue] = useState("");
  const [selectedOptionValueFiltered, setSelectedOptionValueFiltered] =
    useState("");
  const refChats = useRef<HTMLSelectElement | null>(null);
  const refChatsFiltered = useRef<HTMLSelectElement | null>(null);
  const refSearch = useRef<HTMLInputElement | null>(null);
  const [showPreview, setShowPreview] = useState('')
  const [prevSelectedOptionValue, setPrevSelectedOptionValue] = useState("");
  const prevSelectedOptionValueRef = useRef(selectedOptionValue);

  const [filteredOptions, setFilteredOptions] =
    useState<FastChat[]>(fastChatsInfo);

  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setShowPreview(selectedValue)
    
    // Verificar si se seleccionó la misma opción
    if (selectedValue === prevSelectedOptionValue) {
      console.log("La misma opción fue seleccionada");
    }
    
    setSelectedOptionValue(selectedValue);

  };

  const handleOptionSelectFiltered = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    setShowPreview(selectedValue)

     // Verificar si se seleccionó la misma opción
     if (selectedValue === prevSelectedOptionValueRef.current) {
      console.log("La misma opción fue seleccionada");
    }
    
    setSelectedOptionValueFiltered(selectedValue);
    // Actualizar la referencia al valor previo
    prevSelectedOptionValueRef.current = selectedValue;
  };

  useEffect(() => {
    if(refSearch.current && showPreview !== '') {

      if(refSearch.current.value === showPreview) {
        console.log('refSearch.current.value : ', refSearch.current.value )
        console.log('showPreview: ', showPreview)
        // updateInput(refSearch.current.value);
        // onClose();
      }
    }
  }, [showPreview])

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value !== '/'){
      setSearchInput(event.target.value);
      const searchTerm = event.target.value.toLowerCase();
      const filtered = fastChatsInfo.filter((option: FastChat) =>{  
        option.name.toLowerCase().includes(searchTerm)
        if(option.name.toLowerCase().includes(searchTerm)){
          return option
        }
      }
      );
      setFilteredOptions(filtered);
    }else {
      if(refSearch.current) {
        refSearch.current.value = "";
      }
    }

  };

  // useEffect(() => {
  //   // Actualizar el valor previo después de renderizar
  //   setPrevSelectedOptionValue(selectedOptionValue);
  // }, [selectedOptionValue]);

  // useEffect(() => {
  //   // Actualizar el valor previo después de renderizar
  //   setPrevSelectedOptionValue(selectedOptionValueFiltered);
  // }, [selectedOptionValueFiltered]);


  const resetSearchInput = {
    execute: () => {
      setSearchInput("");
      if (refSearch.current) {
        refSearch.current.value = "";
      }
    },
  };

  const resetBusinessSelector = {
    execute: () => {
      if (refChats.current) {
        setSelectedOptionValue("");
        refChats.current.value = "";
      }
      if (refChatsFiltered.current) {
        setSelectedOptionValueFiltered("");
        refChatsFiltered.current.value = "";
      }
    },
  };

  const resetFastChatsInfo = {
    execute: () => {
      setFastChatsInfo([]);
    },
  };

  const onClick: any = () => {
    setFastAnswersIsOpen(true);
    
  };

  const onClose = async () => {
    setFastAnswersIsOpen(false);
    setShowPreview('')
    const resetStrategies = [
      resetSearchInput,
      resetBusinessSelector,
      resetFastChatsInfo,
    ];
    resetStrategies.forEach((strategy) => {
      strategy.execute();
    });
  };

  useEffect(() => {
    updateInput("");
  }, [clientUser]);

  useEffect(() => {
    if(fastAnswersIsOpen) {
      setFastAnswersModal(true)
    }
    else {
      setFastAnswersModal(false)
    }
    if (refSearch.current) {
      refSearch.current.value = ''
      refSearch.current.focus();
    }

  }, [fastAnswersIsOpen])
  
  useEffect(() => {
    const getFastChats = async () => {
      const data = await getFastMessages();
      setFastChatsInfo(data);
    };
    getFastChats();


  }, [fastAnswersIsOpen]);

  useEffect(() => {
    if (fastAnswersIsOpen === false) {
      const resetStrategies = [
        resetSearchInput,
        resetBusinessSelector,
        resetFastChatsInfo,
      ];
      resetStrategies.forEach((strategy) => {
        strategy.execute();
      });
    }

  }, [fastAnswersIsOpen]);

  // const onEnter =(e:any)=> {
  //       if (e.key === 'Enter') {
  //         if (!e.shiftKey) {
  //           updateInput(showPreview);
  //           onClose();
  //         }
  //       }
  // }
  
  // useEffect(() => {
  //   document.addEventListener('keydown', (e) => onEnter(e));

  //   return () => {
  //     document.removeEventListener('keydown', (e) => onEnter(e))
  //   }
  // }, [])

  return (
    <div>
      <button
        onClick={async (event) => {
          event.stopPropagation();
          onClick();
        }}
        className="bg-principalColor hover:bg-[#1db9a5] active:bg-[#199c8b] rounded-lg py-4 px-5 text-gray-100 trainsition-all duration-300 h-13 w-13"
      >
        <FaBolt />
      </button>
      {fastAnswersIsOpen && (
      <div className="fixed top-0 right-0 left-0 z-50 h-modal overflow-y-auto overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        onClick={async (event) => {
          event.stopPropagation();
        }}
      >
        <div className="bg-white rounded-md p-6 h-[70vh] relative">
          <button onClick={() => onClose()} className='absolute right-5 top-5 z-10'><GrClose /></button>
          <div className="flex justify-center items-center h-[90%] w-full">
            <div className="text-center fast-answers-container h-full relative w-full">
              <div className="flex mt-6 relative">
                <input
                  type="text"
                  onChange={handleFilter}
                  className="m-2 w-full h-full rounded-xl focus:border-principalColor hover:border-principalColor focus:bg-white hover:bg-white text-gray-500"
                  ref={refSearch}
                  placeholder="Busca tu mensaje"
                  autoFocus
                />
                <button className="absolute top-1/2 right-2 transform -translate-y-1/2 w-7 h-7 mr-2 flex align-middle justify-center items-center text-center bg-white text-principalColor hover:bg-principalColor hover:text-white rounded-full transition duration-200 ease-in-out">
                  <GiBasket
                    className="w-4 h-4"
                    onClick={() => {
                      setSearchInput("");
                      if (refSearch.current) {
                        refSearch.current.value = "";
                      }
                    }}
                  />
                </button>
              </div>
              <div className="flex justify-center align-middle w-full h-[85%]">
                {!searchInput.length ? (
                  <select
                    value={selectedOptionValue}
                    onChange={handleOptionSelect}
                    className="mx-2 w-full overflow-y-auto rounded-xl hover:border-principalColor h-full"
                    size={5}
                    ref={refChats}
                  >
                    <option value={""}>Elige una opciónssss</option>
                    {fastChatsInfo.map((option: FastChat) => <option key={option.name} value={option.content}>{option.name}</option>)}
                  </select>
                ) : searchInput.length && filteredOptions.length !== 0 ? (
                  <select
                    value={selectedOptionValueFiltered}
                    onChange={handleOptionSelectFiltered}
                    className="mx-2 w-full overflow-y-auto rounded-xl hover:border-principalColor h-full"
                    size={5}
                    ref={refChatsFiltered}
                  >
                    <option value={""}>Elige una opción</option>
                    {filteredOptions.map((option: FastChat) => (
                      <option key={option.name} value={option.content}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <option value="" className="mt-2 pt-2">
                    Sin coincidencias
                  </option>
                )}
              </div>
            </div>
            {showPreview && (
              <div className="w-full h-full relative">
                <p style={{
                      padding: '10px',
                      marginTop: '15px',
                      height: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      borderLeft: '1px solid #dedede',
                      fontSize: 14,
                }}>{showPreview}</p>
              </div>
            )}
          </div>
          <button className="bg-principalColor hover:bg-[#1db9a5] active:bg-[#199c8b] text-white w-full mt-6 rounded-md p-2"
            onClick={() => {
              updateInput(showPreview);
              onClose();
            }}  
            disabled={showPreview === '' ? true : false}
          >
            SELECCIONAR MENSAJE
          </button>
        </div>
      </div>
      )}
    </div>
  );
};

export default FastAnswers;
