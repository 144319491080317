import { useState , SyntheticEvent, FC, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom'

type Props = {
  data: string[],
}

const TabsConfig: FC<Props> = ({data}) => {
  const navigate = useNavigate()
  const [value, setValue] = useState(0);
  const {table} = useParams()

  useEffect(() => {
    const foundIndex = data.findIndex((item) => item === table);
    setValue(foundIndex);
  }, [table])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/config/${data[newValue]}`)
  };

  return (
    <div className='tab-config'>
      <Box sx={{ maxWidth: { xs: 480, sm: 980 }, bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {data.map((item, i) => <Tab label={item} key={i}/>)}
        </Tabs>
      </Box>
    </div>
  );
}

export default TabsConfig