"use client";

import { Button, Modal } from "flowbite-react";
import { useState } from "react";

export interface Props {
  index: any;
  part: any;
}

export const ImageModal: React.FC<Props> = ({ index, part }) => {
  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };

  return (
    <>
      <Button
        onClick={() => props.setOpenModal("dismissible")}
        className="mt-4 bg-principalColor hover:bg-green-600"
      >
        Abrir imagen
      </Button>
      <Modal
        dismissible
        show={props.openModal === "dismissible"}
        onClose={() => props.setOpenModal(undefined)}
      >
        <Modal.Body>
          <div className="flex justify-center">
            <div className="w-full">
              <img
                key={index}
                src={part}
                alt="img-received"
                className="w-full h-full"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
