import { axiosConfig } from "./axios.config";

const getConversation = async (agent_id, chat_id, type ) => {

    const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/get_chat`,
    {
      "agent_id": agent_id,
      "chat_id": chat_id
    }
    );
    return res.data;
};

const sendMessage = async (
    agent_id,
    chat_id,
    body,
    date
  ) => {
    console.log('body service: ', body)
    const message = { 
        'type': "text",
        "content": {
            "preview_url": false,
            "body": body
        },
				date

    }
    const obj = {
			agent_id,
      chat_id,
      message,
      // date,
    };
  
    const res = await axiosConfig.post(
      `${process.env.REACT_APP_API_NEW_URL}/agent/send_message`,
      obj
    );
    
    return res.data;
  };

  const getFastMessages = async () => {
    const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/agent/get_all_responses`);
    return res.data;
};

export { getConversation, sendMessage, getFastMessages }