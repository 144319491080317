import { Routes, Route, Navigate } from "react-router-dom";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import Login from "./pages/Login";
import Messenger from "./pages/Messenger";
import UserAuth from "./utils/UserAuth";
import Home from "./pages/Home";
import Plantillas from "./pages/Plantillas";
// cambio
import "moment/locale/es";
import Config from "./pages/Config";
import Analytics from "./pages/Analytics";

const App: React.FC = () => {
  return (
    <UserAuth>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={<AuthenticationGuard component={Home} />}
        />
        <Route
          path="/inbox"
          element={<AuthenticationGuard component={Messenger} />}
        />
        <Route
          path="/plantillas"
          element={<AuthenticationGuard component={Plantillas} />}
        />
        <Route
          path="/config/:table"
          element={<AuthenticationGuard component={Config} />}
          // element={<Config />}
        />
        <Route
          path="/analytics"
          element={<AuthenticationGuard component={Analytics} />}
        />
      </Routes>
    </UserAuth>
  );
};

export default App;
